
import {
  IonButton,
  IonButtons,
  IonContent, IonFooter,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';
import {defineComponent, inject, onMounted, Ref, ref} from 'vue';
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";
import InputField from "@/components/InputField.vue";
import InputCheckbox from "@/components/InputCheckbox.vue";
import {PreferenceKey} from "@/definitions/PreferenceKey";

export default defineComponent({
  name: 'ProfileSettingsReligion',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonButtons,
    IonButton,
    IonTitle,
    InputCheckbox,
    IonFooter
  },
  setup() {

    const user: Ref<UserInterface | undefined> = inject('user') as Ref<UserInterface | undefined>
    const loading = ref(true)
    const ionRouter = useIonRouter()

    const busy = ref(false)
    const errors = ref({})

    const chosenOptions: Ref<number[]> = ref([])
    const options: any = ref([])
    const showOnProfile: any = ref([true])

    onIonViewWillEnter(async () => {

      if (user.value?.religion) {
        chosenOptions.value = [user.value?.religion.id]
      }

      const pref = Boolean(Number((await ApiService.User().authUser()).preferences[PreferenceKey.ProfileShowReligion]))
      showOnProfile.value = [pref]

      await getOptions()

      loading.value = false
    })

    const getOptions = async () => {
      options.value = (await ApiService.Settings().getReligions()).map((g: any) => {
        return {
          text: g.name,
          value: g.id
        }
      })
    }


    const goBack = async () => {
      if (busy.value) return
      await ionRouter.back()
    }

    const save = async () => {
      if (busy.value) return
      errors.value = {}
      busy.value = true
      try {
        await ApiService.User().setReligion(chosenOptions.value.length ? chosenOptions.value[0] : null)
        await ApiService.User().setPreference(PreferenceKey.ProfileShowReligion, showOnProfile.value[0])
        await Observable.emit(ObservableEvent.UserChanged)
        setTimeout(() => {
          ionRouter.back()
          busy.value = false
        }, 1000)
      } catch (e: any) {
        if (!e.data) return
        if (e.data.errors) errors.value = e.data.errors
        busy.value = false
      }
    }

    return {loading, goBack, user, busy, errors, save, chosenOptions, showOnProfile, options}

  }
});
