
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar, useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, onMounted, ref} from 'vue';
import ApiService from "@/services/api/apiService";
import {DateTime} from "luxon";
import MenuButton from "@/components/MenuButton.vue";
import AuthService from "@/services/api/auth/authService";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import JoinPremiumCard from "@/components/JoinPremiumCard.vue";
import useCamera from "@/composables/useCamera";
import {CameraResultType, CameraSource} from "@capacitor/camera";

export default defineComponent({
  name: 'ProfileSettingsIdCardPage',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonButtons,
    IonButton,
  },
  setup() {

    const ionRouter = useIonRouter()
    const busy = ref(true)
    const status = ref()

    const photo = ref()

    onMounted(async () => {
      status.value = await ApiService.User().getVerificationStatus()
      if (status.value.id_card_photo) photo.value = status.value.id_card_photo
      busy.value = false
    })

    const isImageUrl = computed(()=>{
      if (!photo.value) return
      return photo.value.includes('http')
    })

    const awaitingVerification = computed(()=>{
      if (!status.value) return
      return status.value.submitted_at
    })

    const choosePhoto = async () => {

      if (busy.value || status.value.submitted_at) return

      try {
        const image = await useCamera(CameraSource.Prompt, CameraResultType.Base64)
        busy.value = true
        photo.value = undefined
        if (image && image.base64String) await ApiService.User().setIdCardPhoto(image.base64String)
        photo.value = image.base64String
      } catch (e) {
        busy.value = false
      } finally {
        busy.value = false
      }
    }

    const goBack = async () => {
      await ionRouter.back()
    }

    return {goBack, choosePhoto, busy, photo, isImageUrl, status, awaitingVerification}

  }
});
