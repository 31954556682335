
import {
  IonButton,
  IonButtons,
  IonContent,
  IonPage, onIonViewDidLeave, onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, inject, onMounted, onUnmounted, Ref, ref} from 'vue';
import {useRoute} from "vue-router";
import {FriendUserInterface} from "@/interfaces/FriendUserInterface";
import ApiService from "@/services/api/apiService";
import MenuButton from "@/components/MenuButton.vue";
import {DateTime} from "luxon";
import {useUser} from "@/composables/useUser";
import InputTextarea from "@/components/InputTextarea.vue";
import {Preferences} from "@capacitor/preferences";

export default defineComponent({
  name: 'ReportUserConfirmationPage',
  methods: {useRoute},
  components: {
    IonPage,
    IonContent,
    IonButtons,
    IonButton,
  },
  setup() {

    const loading = ref(true)
    const reportBusy = ref(false)
    const reportAndBlockBusy = ref(false)
    const ionRouter = useIonRouter()
    const route = useRoute()
    const reason = ref()

    const message = ref('')
    const errors = ref({})

    const goBack = async () => {
      await ionRouter.back()
    }

    const reportUser = async () => {
      if (isBusy.value) return
      reportBusy.value = true
      try {
        await ApiService.User().reportUser(Number(route.params.userId), reason.value)

        ionRouter.navigate({
          name: 'ReportUserConfirmationSuccessPage',
          params: {
            userId: route.params.userId,
          }
        }, 'forward', 'push')
      } catch (e) {
        //
      } finally {
        reportBusy.value = false
      }
    }

    const reportAndBlock = async () => {
      if (isBusy.value) return
      reportAndBlockBusy.value = true
      try {
        await ApiService.User().reportAndBlock(Number(route.params.userId), reason.value)
        ionRouter.navigate({
          name: 'ReportUserConfirmationSuccessPage',
          params: {
            userId: route.params.userId,
          }
        }, 'forward', 'push')
      } catch (e) {
        //
      } finally {
        reportAndBlockBusy.value = false
      }
    }

    const isBusy = computed(()=>{
      return reportBusy.value || reportAndBlockBusy.value
    })

    onMounted(()=>{
      console.log(route.params)
      loading.value = false
    })

    onIonViewWillEnter(async ()=>{
      reason.value = (await Preferences.get({key: 'REPORT_USER_REASON'})).value
    })

    onIonViewDidLeave(async ()=> {
      await Preferences.remove({key: 'REPORT_USER_REASON'})
    })

    return {
      goBack,
      reportBusy,
      reportAndBlockBusy,
      reportUser,
      reportAndBlock,
      loading,
      message,
      errors
    }

  }
});
