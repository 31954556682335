


import {computed, defineComponent, onMounted, onUnmounted, PropType, Ref, ref,} from "vue";
import {UserInterface} from "@/interfaces/UserInterface";
import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import {usePost} from "@/composables/usePost";
import FeedPost from "@/components/FeedPost.vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import ApiService from "@/services/api/apiService";
import {UserGalleryInterface} from "@/interfaces/UserGalleryInterface";
import {useUser} from "@/composables/useUser";

export default defineComponent({
  name: 'UserProfileGallery',
  components: {
    IonInfiniteScrollContent,
    IonInfiniteScroll
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
    isAuthUser: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {

    const loading = ref(true)
    const observer = ref()

    const authUserFriends = ref([])

    onMounted(async () => {

      await getGallery(1)
      await getAuthUserFriends()

      observer.value = Observable.subscribe(async (response: any) => {
        if (response.event === ObservableEvent.UserGalleryChanged) {
          await getGallery(1)
        }
      })

      loading.value = false

    })

    onUnmounted(()=>{
      observer.value?.unsubscribe()
    })

    const gallery: Ref<UserGalleryInterface[]> = ref([])
    const pagination = ref()

    const showInfiniteScroll = ref(true)

    const getAuthUserFriends = async () => {
      authUserFriends.value = await ApiService.User().getFriends()
    }

    const getGallery = async (page = 1) => {
      const response = (await ApiService.User().galleryByUserId(props.userId, page))
      pagination.value = response.meta

      if (pagination.value.current_page >= pagination.value.last_page) showInfiniteScroll.value = false

      if (page === 1) {
        gallery.value = response.data
        showInfiniteScroll.value = true
      }

      if (page > 1) {
        gallery.value = [...gallery.value, ...response.data]
      }

    }

    const manageGallery = async () => {
      const user = useUser()
      await user.manageGallery()
    }

    const loadMore = async (ev: any) => {
      await getGallery(pagination.value.current_page + 1)
      ev.target.complete()
    }

    const isFriendsWithAuthUser = computed(()=>{
      if (props.isAuthUser) return true
      if (!authUserFriends.value) return false
      return authUserFriends.value.find((user: any) => user.id === props.userId)
    })

    return {manageGallery, loadMore, showInfiniteScroll, gallery, loading, isFriendsWithAuthUser}

  }

})

