


import {defineComponent, onMounted, onUnmounted, Prop, PropType, Ref, ref,} from "vue";
import {UserInterface} from "@/interfaces/UserInterface";
import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import {usePost} from "@/composables/usePost";
import FeedPost from "@/components/FeedPost.vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import ApiService from "@/services/api/apiService";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";

export default defineComponent({
  name: 'UserProfilePosts',
  components: {
    IonInfiniteScrollContent,
    IonInfiniteScroll,
    FeedPost
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
    isAuthUser: {
      type: Boolean,
      default: false
    },
    authUser: {
      type: Object as PropType<UserInterface>,
      required: false
    }
  },
  setup(props) {

    const loading = ref(true)
    const observer = ref()

    onMounted(async () => {

      await getFeed(1)

      observer.value = Observable.subscribe(async (response: any) => {
        if (response.event === ObservableEvent.FeedPostChanged) {
            const index = feed.value.findIndex(post => {
                return post.id === response.data.id
            })
            Object.keys(response.data).forEach((key:any) => {
                feed.value[index][key] = response.data[key]
            })
        }
      })

      loading.value = false

    })

    onUnmounted(()=>{
      observer.value?.unsubscribe()
    })

    const feed: Ref<(FeedPostInterface|any)[]> = ref([])
    const pagination = ref()

    const showInfiniteScroll = ref(true)

    const getFeed = async (page = 1) => {
      const response = (await ApiService.User().posts(props.userId, page))
      pagination.value = response.meta

      if (pagination.value.current_page >= pagination.value.last_page) showInfiniteScroll.value = false

      if (page === 1) {
        feed.value = response.data
        showInfiniteScroll.value = true
      }

      if (page > 1) {
        feed.value = [...feed.value, ...response.data]
      }

    }

    const createPost = async () => {
      const user = await ApiService.User().get()

      if (!user.id_verified) return

      const post = usePost()
      await post.create(user)
    }

    const loadMore = async (ev: any) => {
      await getFeed(pagination.value.current_page + 1)
      ev.target.complete()
    }

    return {createPost, loadMore, showInfiniteScroll, feed, loading}

  }

})

