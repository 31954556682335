
import {
  IonButton,
  IonButtons,
  IonContent,
  IonPage,
  onIonViewWillEnter,
  toastController,
  useIonRouter,
} from '@ionic/vue';
import {defineComponent, Ref, ref} from 'vue';
import {useRoute} from "vue-router";
import {FriendUserInterface} from "@/interfaces/FriendUserInterface";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";

export default defineComponent({
  name: 'BlockUserPage',
  methods: {useRoute},
  components: {
    IonPage,
    IonContent,
    IonButtons,
    IonButton,
  },
  setup() {

    const loading = ref(true)
    const busy = ref(false)
    const ionRouter = useIonRouter()
    const route = useRoute()

    const reason = ref('')
    const errors = ref({})

    const user: Ref<FriendUserInterface | UserBasicInformationInterface | undefined> = ref()


    const goBack = async () => {
      await ionRouter.back()
    }

    onIonViewWillEnter(async () => {
      user.value = await ApiService.User().getById(Number(route.params.userId))
      loading.value = false
    })

    const blockUser = async () => {
      if (!user.value || busy.value) return
      try {
        busy.value = true
        await ApiService.User().blockUser(user.value.id)
        await Observable.emit(ObservableEvent.FriendsChanged)
        ionRouter.back()
        const toast = await toastController.create({
          header: 'Blocked',
          message: `You've blocked ${user.value?.name} 🚫`,
          duration: 3000,
          position: 'bottom',
          color: 'primary'
        });
        await toast.present();

      } catch (e) {
        //
      } finally {
        busy.value = false
      }
    }

    return {
      goBack,
      busy,
      loading,
      reason,
      errors,
      user,
      blockUser
    }

  }
});
