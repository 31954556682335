

import {defineComponent, inject, onMounted, Ref} from "vue";
import {modalController} from "@ionic/vue";
import SubscriptionPage from "@/views/common/SubscriptionPage.vue";
import {UserInterface} from "@/interfaces/UserInterface";
import StripeSubscriptionPage from "@/views/common/StripeSubscriptionPage.vue";

export default defineComponent({
  name: 'InAppSubscription',
  props: {
    label: {
      type: String,
      default: 'Upgrade to Pineapple Premium'
    }
  },
  setup() {

    const user: Ref<UserInterface> = inject('user') as Ref<UserInterface>

    const showSubscriptionPage = async () => {
      const modal = await modalController.create({
        component: StripeSubscriptionPage
      })
      await modal.present()
      const {data} = await modal.onWillDismiss()
      if (data && data.subscribed) user.value.subscribed = true
    }

    return {
      showSubscriptionPage
    }

  }
});

