import ApiServiceBase from "@/services/api/apiServiceBase";

export class SubscriptionService extends ApiServiceBase {

  public async getTiers(): Promise<any> {
    return this.http?.get('/subscription/tiers');
  }

  public async stripeBillingPortal(): Promise<any> {
    return this.http?.get('/subscription/billing-portal');
  }


}
