
import {IonContent, IonPage, onIonViewWillEnter, useIonRouter,} from '@ionic/vue';
import {defineComponent, onMounted, ref} from 'vue';
import {FcmService} from "@/services/fcm/fcmService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import ApiService from "@/services/api/apiService";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {DateTime} from "luxon";
import {useOneSignal} from "@onesignal/onesignal-vue3";

export default defineComponent({
  name: 'AccountOnboardingNotificationsPage',
  components: {
    IonContent,
    IonPage,
  },
  setup() {

    const busy = ref(false)
    const errors = ref({})
    const ionRouter = useIonRouter()

    onIonViewWillEnter(()=>{
      Observable.emit(ObservableEvent.IonRouterOutletChanged, ionRouter.canGoBack())
    })

    const forward = async () => {
        await ApiService.User().setPreference(PreferenceKey.OnboardingPushNotifications, DateTime.now().toISO())
        await ionRouter.navigate('/pineapple/rules', 'forward', 'push')
    }

    const registerForPush = async () => {

      const oneSignal = useOneSignal()

      if (!oneSignal.Notifications.permission) {
        await oneSignal.Notifications.requestPermission()
      }

      // await FcmService.initPush()
      await forward()
    }

    return {busy, registerForPush, forward, errors}

  }
});
