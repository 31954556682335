
import {
  IonContent,
  IonPage,
  IonInput
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {useRouter} from "vue-router";

export default defineComponent({
  name: 'ForgotPasswordInstructionsPage',
  components: {
    IonContent,
    IonPage,
  },
  setup() {

    return {}

  }
});
