
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';
import {defineComponent, inject, Ref, ref} from 'vue';
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";
import InputCheckbox from "@/components/InputCheckbox.vue";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {AccountType} from "@/definitions/AccountType";

export default defineComponent({
  name: 'ProfileSettingsPiercings',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonButtons,
    IonButton,
    IonTitle,
    InputCheckbox,
  },
  setup() {

    const user: Ref<UserInterface | undefined> = inject('user') as Ref<UserInterface | undefined>
    const loading = ref(true)
    const ionRouter = useIonRouter()

    const busy = ref(false)
    const errors = ref({})

    const chosenOptions: Ref<string[]> = ref([])
    const options: any = ref([])
    const showOnProfile: any = ref([true])

    onIonViewWillEnter(async () => {

      if (user.value?.account_type !== null) {
        chosenOptions.value = [user.value?.account_type as string]
      }

      await getOptions()

      loading.value = false
    })

    const getOptions = async () => {
      options.value = [
        {
          text: 'Individual',
          value: AccountType.Individual
        },
        {
          text: 'Joint',
          value: AccountType.Joint
        },
        {
          text: 'Host',
          value: AccountType.Host
        },
        {
          text: 'Club',
          value: AccountType.Club
        }
      ]
    }


    const goBack = async () => {
      if (busy.value) return
      await ionRouter.back()
    }

    const save = async () => {
      if (busy.value || !chosenOptions.value.length ) return


      const alert = await alertController.create({
        header: 'Verification Required',
        message: `Are you sure you want to change your account type? For the safety of the Pineapple community you will be required to re-submit your ID Verification.`,
        buttons: [
          {
            text: 'Yes',
            handler: async () => {

              if (busy.value) return

              errors.value = {}
              busy.value = true
              try {
                await ApiService.User().setPreference(PreferenceKey.OnboardingAccountType, chosenOptions.value[0])
                await ApiService.User().revokeVerification()
                await Observable.emit(ObservableEvent.UserChanged)
                setTimeout(() => {
                  ionRouter.back()
                  busy.value = false
                }, 1000)
              } catch (e: any) {
                if (!e.data) return
                if (e.data.errors) errors.value = e.data.errors
                busy.value = false
              }

            }
          },
          {
            text: 'Cancel'
          }
        ]
      })

      await alert.present()

    }

    return {loading, goBack, user, busy, errors, save, chosenOptions, showOnProfile, options}

  }
});
