
import {IonContent, IonPage, useIonRouter,} from '@ionic/vue';
import {defineComponent, onMounted, ref} from 'vue';
import {useRoute, useRouter} from "vue-router";
import LogoMark from "@/components/LogoMark.vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

export default defineComponent({
  name: 'ReportUserConfirmationPage',
  methods: {useRoute},
  components: {
    IonPage,
    IonContent,
    LogoMark
  },
  setup() {

    const loading = ref(true)
    const ionRouter = useIonRouter()
    const route = useRoute()
    const router = useRouter()

    const message = ref('')
    const errors = ref({})

    const goBack = async () => {
      await ionRouter.back()
    }

    onMounted(() => {
      loading.value = false
    })

    const done = async () => {
      await Observable.emit(ObservableEvent.FriendsChanged)
      ionRouter.navigate({
        name: 'PineappleUserProfile',
        params: {
          userId: route.params.userId
        }
      }, 'back', 'pop')
    }

    return {
      goBack,
      done,
      loading,
      message,
      errors
    }

  }
});
