
import {
  IonContent,
  IonPage,
  IonInput, modalController
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import ForgotPasswordInstructionsPage from "@/views/auth/ForgotPasswordInstructionsPage.vue";
import {useRouter} from "vue-router";

export default defineComponent({
  name: 'ForgotPasswordPage',
  components: {
    IonContent,
    IonPage,
    IonInput
  },
  setup() {

    const router = useRouter()
    const resetPassword = async () => {
      await router.push({name: 'ForgotPasswordInstructions'})
    }

    return {resetPassword}

  }
});
