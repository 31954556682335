
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonPage, toastController,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, inject, onMounted, onUnmounted, Ref, ref} from 'vue';
import {useRoute} from "vue-router";
import {FriendUserInterface} from "@/interfaces/FriendUserInterface";
import ApiService from "@/services/api/apiService";
import MenuButton from "@/components/MenuButton.vue";
import {DateTime} from "luxon";
import {useUser} from "@/composables/useUser";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";

export default defineComponent({
  name: 'FriendPage',
  methods: {useRoute},
  components: {
    IonPage,
    IonContent,
    IonButtons,
    IonButton,
    MenuButton
  },
  setup() {

    const loading = ref(true)
    const busy = ref(false)
    const ionRouter = useIonRouter()
    const user: Ref<FriendUserInterface | UserInterface | undefined> = ref()
    const route = useRoute()
    const observer = ref()

    const blocked = ref(false)


    const goBack = async () => {
      ionRouter.back()
    }

    const getData = async () => {
      try {
        user.value = await ApiService.User().getById(Number(route.params.userId))
      } catch (e) {
        if (!user.value) return
        blocked.value = true
      }
      loading.value = false
    }

    onMounted(async () => {
      await getData()
      observer.value = Observable.subscribe(async (response:any) => {
        if (response.event === ObservableEvent.FriendsChanged) {
          await getData()
        }
      })
    })

    const removeFriend = async () => {
      try {
        await useUser().removeFriend(user.value as FriendUserInterface)
        ionRouter.back()
      } catch (e) {
        //
      }
    }

    const friendRequest = async () => {
      if (!user.value) return
      user.value = await useUser().makeFriendRequest(user.value?.id)
    }

    const reportUser = async () => {
      ionRouter.navigate({
        name: 'ReportUserPage',
        params: {
          userId: user.value?.id
        }
      }, 'forward', 'push')
    }

    const blockUser = async () => {
      ionRouter.navigate({
        name: 'BlockUserPage',
        params: {
          userId: user.value?.id
        }
      }, 'forward', 'push')
    }

    return {
      goBack,
      busy,
      loading,
      user,
      removeFriend,
      friendRequest,
      reportUser,
      blockUser,
      blocked
    }

  }
});
