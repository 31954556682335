
import {
  IonContent, IonFooter,
  IonPage, IonToolbar, onIonViewDidEnter, onIonViewWillEnter, useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, onMounted, ref} from 'vue';
import InputCheckbox from "@/components/InputCheckbox.vue";
import ApiService from "@/services/api/apiService";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

export default defineComponent({
  name: 'AccountOnboardingGenderPage',
  components: {
    InputCheckbox,
    IonContent,
    IonPage,
    IonFooter,
    IonToolbar,
  },
  setup() {

    const loading = ref(true)
    const busy = ref(false)
    const gender = ref([])

    const errors = ref({})
    const ionRouter = useIonRouter()

    onIonViewWillEnter(()=>{
      Observable.emit(ObservableEvent.IonRouterOutletChanged, ionRouter.canGoBack())
    })

    const forward = async () => {
      if (!gender.value.length || busy.value) return
      busy.value = true
      try {
        await ApiService.User().setGender(gender.value[0])
        await ApiService.User().setPreference(PreferenceKey.ProfileShowGender, showOnProfile.value[0])
        await ionRouter.navigate('/account/onboarding/sexuality', 'forward', 'push')
      } catch (e: any) {
        if (!e.data) return
        if (e.data.errors) errors.value = e.data.errors
      } finally {
        busy.value = false
      }
    }

    onMounted(async ()=>{
      await getGenderOptions()
      loading.value = false
    })

    const getGenderOptions = async () => {
      genderOptions.value = (await ApiService.Settings().getGenders()).map((g:any) => {
        return {
          text: g.name,
          value: g.id
        }
      })
    }

    const canProgress = computed(() => {
      return !gender.value.length
    })

    const genderOptions: any = ref([])
    const showOnProfile: any = ref([])

    return {busy, forward, gender, genderOptions, showOnProfile, errors, canProgress}

  }
});
