import ApiServiceBase from "@/services/api/apiServiceBase";
import {isPlatform} from "@ionic/vue";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {Glassfy} from "capacitor-plugin-glassfy";
import {UserInterface} from "@/interfaces/UserInterface";
import {StatusInterface} from "@/interfaces/StatusInterface";
import {User} from "@sentry/capacitor";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import {UserViewerInterface} from "@/interfaces/UserViewerInterface";

export class UserService extends ApiServiceBase {


  public async authUser(): Promise<UserInterface> {
    const user = (await Preferences.get({key: PreferenceKey.AuthUser})).value
    if (!user) return this.get()
    return JSON.parse(user as string) as UserInterface
  }

  public async delete(): Promise<any> {
    return this.http?.delete('/user', {});
  }

  public async get(): Promise<UserInterface> {
    const user = await this.http?.get(`/user?with=preferences,onboarding,dob`);
    await Preferences.set({key: PreferenceKey.AuthUser, value: JSON.stringify(user)})
    return user
  }

  public async getById(id: number, withPreferences = false): Promise<UserInterface> {
    return this.http?.get(`/user/${id}${withPreferences ? '?with=preferences' : ''}`);
  }

  public async posts(id: number, page = 1): Promise<any> {
    return this.http?.get(`/user/${id}/posts?page=${page}`);
  }

  public async gallery(page = 1): Promise<any> {
    return this.http?.get(`/user/gallery?page=${page}`);
  }

  public async galleryByUserId(userId: number, page = 1): Promise<any> {
    return this.http?.get(`/user/${userId}/gallery?page=${page}`);
  }

  public async updateGalleryMedia(images:string[] = []): Promise<any> {
    return this.http?.post(`/user/gallery/media`, {images});
  }

  public async getVerificationStatus(): Promise<StatusInterface> {
    return this.http?.get('/user/verification/status');
  }

  public async submitIdForVerification(): Promise<any> {
    return this.http?.post('/user/verification/submit-id', {});
  }

  public async cancelIdVerification(): Promise<any> {
    return this.http?.delete('/user/verification/submit-id', {});
  }

  public async setPreference(key: string, value: string): Promise<any> {
    return this.http?.put('/user/preference', {
      key,
      value
    });
  }

  public async setName(name: string): Promise<any> {
    return this.http?.put('/user/profile/name', {
      name
    });
  }

  public async setGender(id: number): Promise<any> {
    return this.http?.put('/user/profile/gender', {
      id
    });
  }

  public async setSexuality(id: number): Promise<any> {
    return this.http?.put('/user/profile/sexuality', {
      id
    });
  }

  public async setSexualInterests(id: string): Promise<any> {
    return this.http?.put('/user/profile/sexual-interests', {
      id
    });
  }

  public async setDesires(id: string): Promise<any> {
    return this.http?.put('/user/profile/desires', {
      id
    });
  }

  public async setDob(dob: string): Promise<any> {
    return this.http?.put('/user/profile/dob', {
      dob
    });
  }

  public async setBio(bio: string): Promise<any> {
    return this.http?.put('/user/profile/bio', {
      bio
    });
  }

  public async setSubscriberId(): Promise<any> {
    let userId: any = (await Preferences.get({key: PreferenceKey.UserId})).value
    if (!userId) userId = (await this.get()).id
    await Glassfy.connectCustomSubscriber({ subscriberId: `${userId}` });
    return userId
  }

  public async getPreference(key: string, userId?: number): Promise<any> {
    if (!userId) userId = Number((await Preferences.get({key: PreferenceKey.UserId})).value)
    const preference = await this.http?.get(`/user/${userId}/preference`, {
      key
    });
    return preference ? preference.value : null
  }

  public async getPreferences(keys: string[], userId?: number): Promise<any> {
    if (!userId) userId = Number((await Preferences.get({key: PreferenceKey.UserId})).value)
    return this.http?.get(`/user/${userId}/preferences`, {
      keys
    });
  }


  public async sendEmailVerification(): Promise<boolean> {
    return this.http?.post('/user/verification/email-notification', {});
  }

  public async registerDeviceToken(token: string): Promise<unknown> {
    if (!isPlatform('capacitor')) return Promise.resolve()
    await this.http?.put(`/user/token`, {
      type: 'device',
      token: token,
    })
    await Preferences.set({key: PreferenceKey.DeviceToken, value: `${token}`})
    return Promise.resolve(token)
  }

  public async revokeDeviceToken(): Promise<unknown> {
    const token = (await Preferences.get({key: PreferenceKey.DeviceToken})).value
    if (!token) return
    await this.http?.put(`/user/token/revoke`, {
      type: 'device',
      token: token
    })
    await Preferences.remove({key: PreferenceKey.DeviceToken})
  }

  public async setProfilePhoto(image: string): Promise<UserInterface> {
    return this.http?.post('/user/profile/photo', {
      image
    }).then(async (user)=>{
      return user
    });
  }

  public async setIdCardPhoto(image: string): Promise<UserInterface> {
    return this.http?.post('/user/verification/id-card-photo', {
      image
    }).then(async (user)=>{
      return user
    });
  }

  public async setIdPhoto(image: string): Promise<UserInterface> {
    return this.http?.post('/user/verification/id-photo', {
      image
    }).then(async (user)=>{
      return user
    });
  }

  public async changePassword(password: string, password_confirmation: string) {
    return this.http?.put('/user/profile/password', {
      password,
      password_confirmation,
    });
  }

  public async getNotifications(page=1): Promise<any> {
    return this.http?.get(`/user/notifications?page=${page}`);
  }

  public async getNotificationById(id:number): Promise<any> {
    return this.http?.get(`/user/notifications/${id}`);
  }

  public async getUnseenNotifications(): Promise<any> {
    return this.http?.get(`/user/notifications/unseen`);
  }

  public async deleteAllNotifications(): Promise<any> {
    return this.http?.delete(`/user/notifications`, {});
  }

  public async seenNotifications(ids: string): Promise<any> {
    return this.http?.post(`/user/notifications`, {
      ids
    });
  }

  public async getFriend(id: number): Promise<any> {
    return this.http?.get(`/user/friend/${id}`);
  }

  public async getFriends(): Promise<any> {
    return this.http?.get(`/user/friends`);
  }

  public async getFriendsForUser(id:number): Promise<any> {
    return this.http?.get(`/user/${id}/friends`);
  }

  public async getFollowingForUserId(id:number): Promise<any> {
    return this.http?.get(`/user/${id}/following`);
  }

  public async getFollowersForUserId(id:number): Promise<any> {
    return this.http?.get(`/user/${id}/followers`);
  }

  public async getFollowing(): Promise<any> {
    return this.http?.get(`/user/following`);
  }

  public async getWinksForUser(id:number): Promise<any> {
    return this.http?.get(`/user/${id}/winks`);
  }

  public async getFriendRequests(): Promise<any> {
    return this.http?.get(`/user/friends/requests`);
  }

  public async getFriendRequestsSent(): Promise<any> {
    return this.http?.get(`/user/friends/requested`);
  }

  public async makeFriendRequest(id: number): Promise<any> {
    return this.http?.put(`/user/friends/request`, {
      id
    });
  }

  public async rejectFriendRequest(id: number): Promise<any> {
    return this.http?.delete(`/user/friends/request`, {
      id
    });
  }

  public async removeFromFriends(id: number): Promise<any> {
    return this.http?.delete(`/user/friends`, {
      id
    });
  }

  public async getPartner(id: number): Promise<any> {
    return this.http?.get(`/user/partner/${id}`);
  }

  public async getPartners(): Promise<any> {
    return this.http?.get(`/user/partners`);
  }

  public async getPartnersForUser(id:number): Promise<any> {
    return this.http?.get(`/user/${id}/partners`);
  }

  public async getPartnerRequests(): Promise<any> {
    return this.http?.get(`/user/partners/requests`);
  }

  public async getPartnerRequestsSent(): Promise<any> {
    return this.http?.get(`/user/partners/requested`);
  }

  public async makePartnerRequest(id: number): Promise<any> {
    return this.http?.put(`/user/partners/request`, {
      id
    });
  }

  public async rejectPartnerRequest(id: number): Promise<any> {
    return this.http?.delete(`/user/partners/request`, {
      id
    });
  }

  public async removeFromPartners(id: number): Promise<any> {
    return this.http?.delete(`/user/partners`, {
      id
    });
  }

  public async reportUser(id: number, reason: string): Promise<any> {
    return this.http?.put(`/user/${id}/report`, {
      id,
      reason
    });
  }

  public async blockUser(id: number): Promise<any> {
    return this.http?.put(`/user/${id}/block`, {
      id
    });
  }

  public async unblockUser(id: number): Promise<any> {
    return this.http?.delete(`/user/${id}/block`, {
      id
    });
  }

  public async blocked(): Promise<any> {
    return this.http?.get(`/user/blocked`);
  }

  public async winkAtUser(userId: number): Promise<any> {
    return this.http?.put(`/user/${userId}/wink`, {});
  }

  public async reportAndBlock(id: number, reason: string): Promise<any> {
    await this.reportUser(id, reason)
    await this.blockUser(id)
    return true
  }

  public async setPronouns(id: number|null): Promise<any> {
    const data: any = {}
    if (typeof id === 'number') data['id'] = id
    return this.http?.put('/user/profile/pronouns', data);
  }

  public async setReligion(id: number|null): Promise<any> {
    const data: any = {}
    if (typeof id === 'number') data['id'] = id
    return this.http?.put('/user/profile/religion', data);
  }

  public async setBodyType(id: number|null): Promise<any> {
    const data: any = {}
    if (typeof id === 'number') data['id'] = id
    return this.http?.put('/user/profile/body-type', data);
  }

  public async setDrinkingType(id: number|null): Promise<any> {
    const data: any = {}
    if (typeof id === 'number') data['id'] = id
    return this.http?.put('/user/profile/drinking-type', data);
  }

  public async setSmokingType(id: number|null): Promise<any> {
    const data: any = {}
    if (typeof id === 'number') data['id'] = id
    return this.http?.put('/user/profile/smoking-type', data);
  }

  public async setHeight(heightInCM: number|null): Promise<any> {
    const data: any = {}
    if (typeof heightInCM === 'number') data['value'] = heightInCM
    return this.http?.put('/user/profile/height', data);
  }

  public async setPiercings(value: boolean|null): Promise<any> {
    const data:any = {}
    if (typeof value === 'boolean') data['value'] = value
    return this.http?.put('/user/profile/piercings', data);
  }

  public async setTattoos(value: boolean|null): Promise<any> {
    const data:any = {}
    if (typeof value === 'boolean') data['value'] = value
    return this.http?.put('/user/profile/tattoos', data);
  }

  public async setLocation(location: any): Promise<any> {
    return this.http?.put('/user/profile/location', location);
  }

  public async reportProblem(problem:string): Promise<any> {
    return this.http?.post(`/user/problem`, {problem});
  }

  public async revokeVerification(): Promise<any> {
    return this.http?.delete(`/user/verification`, {});
  }

  public async updateCurrentPosition(lat: number, lng: number): Promise<any> {
    return this.http?.put(`/user/location`, {
      lat,
      lng
    });
  }

  public async views(): Promise<UserViewerInterface[]> {
    return this.http?.get(`/user/views`, {});
  }

  public async followUser(id: number): Promise<any> {
    return this.http?.put(`/user/following/request`, {
      id
    });
  }

  public async unfollowUser(id: number): Promise<any> {
    return this.http?.delete(`/user/following`, {
      id
    });
  }

  public async setAvailability(duration: number): Promise<any> {
    return this.http?.put('/user/available-to-meet', {
      duration
    });
  }

  public async availableToMeet(): Promise<any> {
    return this.http?.get('/user/available-to-meet', {

    });
  }

}
