
import {
  IonContent,
  IonPage, IonTitle, IonToolbar, useIonRouter,
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {DateTime} from "luxon";
import VerifiedStatusCard from "@/components/VerifiedStatusCard.vue";

export default defineComponent({
  name: 'PineappleRulesPage',
  components: {
    VerifiedStatusCard,
    IonContent,
    IonPage,
    IonToolbar
  },
  setup() {

    const busy = ref(false)
    const ionRouter = useIonRouter()

    const forward = async () => {
      await ionRouter.navigate('/pineapple/feed', 'forward', 'replace')
    }

    return {busy, forward}

  }
});
