
import {
  IonContent,
  IonPage, useIonRouter,
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import InputField from "@/components/InputField.vue";
import AuthService from "@/services/api/auth/authService";
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
  name: 'AccountEmailPage',
  components: {
    IonContent,
    IonPage,
    InputField
  },
  setup() {

    const busy = ref(false)
    const authService = new AuthService()
    const email = ref('')
    const errors = ref({})
    const ionRouter = useIonRouter()

    const registerAccount = async () => {
      if (!email.value || busy.value) return
      busy.value = true
      try {
        const password = uuidv4()
        await authService.register(email.value, password)
        await authService.login(email.value, password)

        await ionRouter.navigate('/account/email/not-verified', 'forward', 'push')
        email.value = ''
      } catch (e:any) {
        if (!e.data) return
        if (e.data.errors) errors.value = e.data.errors
      } finally {
        busy.value = false
      }
    }

    return {busy,registerAccount, email, errors}

  }
});
