


import {computed, defineComponent, onMounted, ref, Ref} from "vue";
import {
  alertController,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonSkeletonText, modalController,
  useIonRouter
} from "@ionic/vue";
import ApiService from "@/services/api/apiService";
import pluralize from "pluralize"
import {usePost} from "@/composables/usePost";
import UserProfilePosts from "@/components/UserProfilePosts.vue";
import {useUser} from "@/composables/useUser";
import {FriendUserInterface} from "@/interfaces/FriendUserInterface";
import UserProfileGallery from "@/components/UserProfileGallery.vue";
import UserProfilePersonal from "@/components/UserProfilePersonal.vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import inAppSubscription from "@/components/InAppSubscription.vue";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import ProfileFriendsUsersList from "@/views/user/profile/ProfileFriendsUsersList.vue";
import ProfileWinksUsersList from "@/views/user/profile/ProfileWinksUsersList.vue";
import FlirtViewChat from "@/views/flirt/FlirtViewChat.vue";
import {usePremium} from "@/composables/usePremium";
import {useVerification} from "@/composables/useVerification";
import UserAccountTypeBadge from "@/components/UserAccountTypeBadge.vue";
import UserProfileViews from "@/components/UserProfileViews.vue";
import UserBadges from "@/components/UserBadges.vue";
import ProfileFollowingUsersList from "@/views/user/profile/ProfileFollowingUsersList.vue";
import ProfileFollowersUsersList from "@/views/user/profile/ProfileFollowersUsersList.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import {UserInterface} from "@/interfaces/UserInterface";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";

export default defineComponent({
  name: 'UserProfile',
  components: {
    UserAvatar,
    UserBadges,
    UserProfileViews,
    UserAccountTypeBadge,
    UserProfilePosts,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    UserProfileGallery,
    UserProfilePersonal,
    IonSkeletonText,
    inAppSubscription
  },
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  emits: ['onSetUser'],
  setup(props, {emit}) {

    const loading = ref(true)
    const ionRouter = useIonRouter()
    const tab = ref('personal')

    const user: Ref<UserInterface | undefined> = ref()
    const authUser: Ref<UserInterface | undefined> = ref()

    const friendsBusy = ref(false)

    const profileViewReceipts = computed(() => {
      if (!authUser.value) return false
      return Number(authUser.value?.preferences[PreferenceKey.ProfileViewReceipts])
    })

    onMounted(async () => {

      try {
        await getData()
      } catch (e:any) {
        if (e.status===404) {

          const alert = await alertController.create({
            header: 'Not available',
            subHeader: 'The requested user is no longer available.',
            buttons: [
              {
                text: 'Ok',
                handler: async () => {
                  alertController.dismiss()
                  await goBack()
                }
              }
            ]
          })
          return await alert.present()

        }
      }

      Observable.subscribe(async (response: any) => {
        if ([ObservableEvent.UserChanged, ObservableEvent.UserGalleryChanged].includes(response.event)) {
          console.log('updated')
          await getData()
        }
      })

      if (isAuthUser.value) tab.value = 'views'
      if (!profileViewReceipts.value) tab.value = 'personal'

      loading.value = false

    })

    const getUser = async () => {
      user.value = await ApiService.User().getById(props.userId, true)
    }

    const getData = async () => {

      authUser.value = await ApiService.User().authUser()
      user.value = authUser.value

      if (authUser.value?.id !== props.userId) await getUser()
      emit('onSetUser', user.value)

    }

    const showLocation = computed(() => {
      return Boolean(Number(user.value?.preferences[PreferenceKey.ProfileShowLocation]))
    })

    const isAuthUser = computed(() => {
      if (!authUser.value) return false
      if (authUser.value?.id === user.value?.id) return true
      return false
    })

    const isFollowing = computed(() => {
      if (loading.value) return false
      return user.value?.is_following
    })

    const isFriends = computed(() => {
      if (loading.value) return false
      return user.value?.is_friend
    })

    const isFriendRequested = computed(() => {
      if (loading.value) return false
      return user.value?.is_friend_requested
    })

    const goBack = async () => {
      await ionRouter.back()
    }

    const profileTabChanged = (event: any) => {
      tab.value = event.detail.value
    }

    const createPost = async () => {
      const post = usePost()
      await post.create(authUser.value as UserInterface)
    }

    const followBusy = ref(false)
    const follow = async () => {
      if (followBusy.value || !user.value) return

      if (isFollowing.value) {

        const alert = await alertController.create({
          header: 'Unfollow',
          message: `Are you sure you want to unfollow ${user.value.name}?`,
          buttons: [
            {
              text: 'Cancel',
              handler: async () => {
                followBusy.value = false
                await alertController.dismiss(false)
              }
            },
            {
              text: 'Yes',
              role: 'destructive',
              handler:  async () => {
                followBusy.value = true
                alertController.dismiss()
                await useUser().unfollowUser(user.value?.id as number)
                await getUser()
                return followBusy.value = false
              }
            },
          ]
        })
        await alert.present()

      }
      if (!isFollowing.value) {
        followBusy.value = true
        await useUser().followUser(user.value.id)
        await getUser()
        return followBusy.value = false
      }
    }

    const friendRequest = async () => {
      if (friendsBusy.value || !user.value) return
      if (!authUser.value?.id_verified) return await useVerification().presentVerificationRequired('You must be verified to send friend requests.')
      friendsBusy.value = true

      await useUser().makeFriendRequest(user.value.id)
      await getUser()
      friendsBusy.value = false
    }

    const cancelFriendRequest = async () => {
      if (friendsBusy.value) return
      friendsBusy.value = true
      try {
        await useUser().cancelFriendRequest(user.value as any, async (u: any) => {
          await getUser()
          friendsBusy.value = false
        })
      } catch (e) {
        friendsBusy.value = false
      }
    }

    const removeFriend = async () => {
      if (friendsBusy.value) return
      friendsBusy.value = true
      try {
        await useUser().removeFriend(user.value as any, async (u: any) => {
          await getUser()
          friendsBusy.value = false
        })
      } catch (e) {
        friendsBusy.value = false
      }
    }

    const winkBusy = ref(false)
    const wink = async () => {
      if (!user.value) return
      if (winkBusy.value) return
      if (!authUser.value?.id_verified) return await useVerification().presentVerificationRequired('You must be verified to wink at members.')
      winkBusy.value = true

      await useUser().winkAtUser(user.value.id, user.value?.name)
      await getUser()
      winkBusy.value = false
    }

    const viewFriends = async () => {

      if (isAuthUser.value) {
        return ionRouter.push({
          name: 'Friends'
        })
      }

      if (!user.value?.friends) return

      const sheet = await modalController.create({
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        component: ProfileFriendsUsersList,
        componentProps: {
          userId: user.value?.id
        },
        mode: 'ios'
      })
      await sheet.present()
      const data = (await sheet.onDidDismiss()).data

      if (Object.prototype.hasOwnProperty.call(data, 'user')) {
        ionRouter.navigate({
          name: 'PineappleUserProfile',
          params: {
            userId: data.user.id
          }
        }, 'forward', 'push')
      }

    }

    const viewFollowing = async () => {

      if (!user.value?.following) return

      const sheet = await modalController.create({
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        component: ProfileFollowingUsersList,
        componentProps: {
          userId: user.value?.id
        },
        mode: 'ios'
      })
      await sheet.present()
      const data = (await sheet.onDidDismiss()).data

      if (Object.prototype.hasOwnProperty.call(data, 'user')) {
        ionRouter.navigate({
          name: 'PineappleUserProfile',
          params: {
            userId: data.user.id
          }
        }, 'forward', 'push')
      }

    }

    const viewFollowers = async () => {

      if (!user.value?.followers) return

      const sheet = await modalController.create({
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        component: ProfileFollowersUsersList,
        componentProps: {
          userId: user.value?.id
        },
        mode: 'ios'
      })
      await sheet.present()
      const data = (await sheet.onDidDismiss()).data

      if (Object.prototype.hasOwnProperty.call(data, 'user')) {
        ionRouter.navigate({
          name: 'PineappleUserProfile',
          params: {
            userId: data.user.id
          }
        }, 'forward', 'push')
      }

    }

    const viewWinks = async () => {

      if (!user.value?.winks) return

      const sheet = await modalController.create({
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        component: ProfileWinksUsersList,
        componentProps: {
          userId: user.value?.id
        },
        mode: 'ios'
      })
      await sheet.present()
      const data = (await sheet.onDidDismiss()).data

      if (Object.prototype.hasOwnProperty.call(data, 'user')) {
        ionRouter.navigate({
          name: 'PineappleUserProfile',
          params: {
            userId: data.user.id
          }
        }, 'forward', 'push')
      }

    }

    const directMessage = async () => {

      if (!authUser.value?.subscribed) return usePremium().presentPayWall({
        text: 'Upgrade to send direct messages, premium members can respond immediately.'
      })

      const chat = await ApiService.Chat().with(user.value?.id as number)

      ionRouter.navigate({
        name: 'FlirtViewChat',
        params: {
          chatId: chat.id
        }
      }, 'forward', 'push')

    }

    const allowDirectMessage = computed(() => {
      if (user.value?.preferences[PreferenceKey.OnlyFriendsCanDirectMessage] === '0') return true
      return isFriends.value;
    })

    return {
      loading,
      friendsBusy,
      winkBusy,
      wink,
      directMessage,
      friendRequest,
      removeFriend,
      isAuthUser,
      isFriends,
      isFriendRequested,
      user,
      createPost,
      pluralize,
      profileTabChanged,
      tab,
      goBack,
      authUser,
      showLocation,
      viewFriends,
      viewWinks,
      PreferenceKey,
      allowDirectMessage,
      cancelFriendRequest,
      profileViewReceipts,
      isFollowing,
      follow,
      followBusy,
      viewFollowers,
      viewFollowing
    }
  }
})
