
import {
  IonContent, IonFooter,
  IonPage, IonToolbar, onIonViewWillEnter, useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, onMounted, ref} from 'vue';
import InputCheckbox from "@/components/InputCheckbox.vue";
import ApiService from "@/services/api/apiService";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {Preferences} from "@capacitor/preferences";
import {AccountType} from "@/definitions/AccountType";

export default defineComponent({
  name: 'AccountOnboardingSexualityPage',
  components: {
    InputCheckbox,
    IonContent,
    IonPage,
    IonFooter,
    IonToolbar,
  },
  setup() {

    const loading = ref(true)
    const busy = ref(false)
    const sexuality = ref([])

    const errors = ref({})
    const ionRouter = useIonRouter()
    const accountType = ref()

    onIonViewWillEnter(async ()=>{
      Observable.emit(ObservableEvent.IonRouterOutletChanged, ionRouter.canGoBack())
      accountType.value = (await Preferences.get({key: PreferenceKey.OnboardingAccountType})).value
    })

    const forward = async () => {
      if (!sexuality.value.length || busy.value) return
      busy.value = true
      try {
        await ApiService.User().setSexuality(sexuality.value[0])
        await ApiService.User().setPreference(PreferenceKey.ProfileShowSexuality, showOnProfile.value[0])
        await ionRouter.navigate('/account/onboarding/interested-in', 'forward', 'push')
      } catch (e: any) {
        if (!e.data) return
        if (e.data.errors) errors.value = e.data.errors
      } finally {
        busy.value = false
      }
    }

    const canProgress = computed(() => {
      return !sexuality.value.length
    })


    onMounted(async ()=>{
      await getSexualityOptions()
      loading.value = false
    })

    const getSexualityOptions = async () => {
      sexualityOptions.value = (await ApiService.Settings().getSexualities())
          .filter((r:any)=>{
            return accountType.value === AccountType.Individual ? !r.combination : r.combination
          })
          .map((r:any) => {
        return {
          text: r.name,
          value: r.id
        }
      })
    }

    const sexualityOptions: any = ref([])
    const showOnProfile: any = ref([])

    return {busy, forward, sexuality, sexualityOptions, showOnProfile, errors, canProgress}

  }
});
