import {NotificationInterface} from "@/interfaces/NotificationInterface";
import {useIonRouter, UseIonRouterResult} from "@ionic/vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

export function useNotification() {

    const present = async (notification: NotificationInterface, ionRouter?: UseIonRouterResult) => {

        console.log(notification)

        if (
            (notification.context.type === 'Spatie\\Comments\\Models\\Comment' && notification.context.post) ||
            (notification.context.type === "App\\Models\\FeedPost" && notification.context.post)
        ) {
            if (ionRouter) ionRouter.navigate({
                name: 'FeedViewPost',
                params: {
                    postId: notification.context.post.id
                }
            }, 'forward', 'push')
        }

        if ((notification.context.type === "App\\Models\\User")){
            if (ionRouter) ionRouter.navigate({
                name: 'PineappleUserProfile',
                params: {
                    userId: notification.notified_by.id
                }
            }, 'forward', 'push')
        }

        if ((notification.context.type === "App\\Models\\Event")){
            if (ionRouter) ionRouter.navigate({
                name: 'Event',
                params: {
                    eventId: notification.context.id
                }
            }, 'forward', 'push')
        }

    }

    return {
        present
    }

}