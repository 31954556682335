export enum PreferenceKey {
    CompletedOnboarding = "CompletedOnboarding",
    CompletedProfileOnboarding = "CompletedProfileOnboarding",
    ApiToken = "ApiToken",
    EmailVerified = "EmailVerified",
    DeviceToken = "DeviceToken",
    AccountType = "AccountType",
    OnboardingAccountType = 'ONBOARDING_ACCOUNT_TYPE',
    OnboardingPushNotifications = 'ONBOARDING_PUSH_NOTIFICATIONS',
    OnboardingCompleted = 'ONBOARDING_COMPLETED',
    ProfileShowGender = 'PROFILE_SHOW_GENDER',
    ProfileShowSexuality = 'PROFILE_SHOW_SEXUALITY',
    ProfileShowSexualInterests = 'PROFILE_SHOW_SEXUAL_INTERESTS',
    ProfileShowDesires = 'PROFILE_SHOW_DESIRES',
    ProfileShowPronouns = 'PROFILE_SHOW_PRONOUNS',
    ProfileShowBodyType = 'PROFILE_SHOW_BODY_TYPE',
    ProfileShowDrinkingType = 'PROFILE_SHOW_DRINKING_TYPE',
    ProfileShowSmokingType = 'PROFILE_SHOW_SMOKING_TYPE',
    ProfileShowHeight = 'PROFILE_SHOW_HEIGHT',
    ProfileShowPiercings = 'PROFILE_SHOW_PIERCINGS',
    ProfileShowReligion = 'PROFILE_SHOW_RELIGION',
    ProfileShowTattoos = 'PROFILE_SHOW_TATTOOS',
    ProfileShowLocation = 'PROFILE_SHOW_LOCATION',
    ProfileShowLastOnline = 'PROFILE_SHOW_LAST_ONLINE',
    UserId = 'USER_ID',
    AuthUser = 'AUTH_USER',
    ActiveChatId = 'ACTIVE_CHAT_ID',
    AllowFriendRequests = 'ALLOW_FRIEND_REQUESTS',
    OnlyFriendsCanDirectMessage = 'ONLY_FRIENDS_CAN_DIRECT_MESSAGE',
    OnlyFriendsCanAddYouToGroupChats = 'ONLY_FRIENDS_CAN_ADD_YOU_TO_GROUP_CHATS',
    NotifyWhenSomebodyLikesMyPost = 'NOTIFY_WHEN_SOMEBODY_LIKES_MY_POST',
    NotifyWhenSomebodyLikesMyComment = 'NOTIFY_WHEN_SOMEBODY_LIKES_MY_COMMENT',
    NotifyWhenSomebodyRepliesToMyPost = 'NOTIFY_WHEN_SOMEBODY_REPLIES_TO_MY_POST',
    NotifyWhenSomebodyRepliesToMyComment = 'NOTIFY_WHEN_SOMEBODY_REPLIES_TO_MY_COMMENT',
    NotifyWhenSomebodyMentionsMe = 'NOTIFY_WHEN_SOMEBODY_MENTIONS_ME',
    NotifyWhenSomebodySendsAFriendRequest = 'NOTIFY_WHEN_SOMEBODY_SENDS_A_FRIEND_REQUEST',
    NotifyWhenSomebodyAcceptsYourFriendRequest = 'NOTIFY_WHEN_SOMEBODY_ACCEPTS_YOUR_FRIEND_REQUEST',
    NotifyWhenSomebodyWinksAtMe = 'NOTIFY_WHEN_SOMEBODY_WINKS_AT_ME',
    NotifyWhenSomebodyStartsFollowingMe = 'NOTIFY_WHEN_SOMEBODY_STARTS_FOLLOWING_ME',
    NotifyWhenSomebodyAttends = 'NOTIFY_WHEN_SOMEBODY_ATTENDS',
    NotifyWhenSomebodyShowsInterest = 'NOTIFY_WHEN_SOMEBODY_SHOWS_INTEREST',
    NotifyWhenSomebodyRequestsAnInvite = 'NOTIFY_WHEN_SOMEBODY_REQUESTS_AN_INVITE',
    NotifyWhenSomebodyAcceptsAnInvite = 'NOTIFY_WHEN_SOMEBODY_ACCEPTS_AN_INVITE',
    LocationDiscoverable = 'LOCATION_DISCOVERABLE',
    LocationAccuracy = 'LOCATION_ACCURACY',
    ProfileViewReceipts = 'PROFILE_VIEW_RECEIPTS',
    PrivacyPin = 'PRIVACY_PIN',
    DailyXRated = 'DAILY_X_RATED',
    FeedShowXRated = 'FEED_SHOW_X_RATED',

}