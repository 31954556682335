
import {
  IonContent,
  IonPage,
  useIonRouter
} from '@ionic/vue';
import {computed, defineComponent, reactive, ref} from 'vue';
import InputField from "@/components/InputField.vue";
import {UserService} from "@/services/api/user/userService";

export default defineComponent({
  name: 'AccountPasswordPage',
  components: {
    InputField,
    IonContent,
    IonPage,
  },
  setup() {

    const ionRouter = useIonRouter()
    const errors = ref({})
    const busy = ref(false)
    const form = reactive({
      password: '',
      password_confirmation: ''
    })

    const userService = new UserService()

    const canSetPassword = computed(()=>{
      return !!(form.password && form.password_confirmation);
    })

    const setPassword = async () => {
      if (!canSetPassword.value || busy.value) return
      busy.value = true
      try {

        await userService.changePassword(form.password, form.password_confirmation)
        form.password = ''
        form.password_confirmation = ''

        await ionRouter.navigate('/account/onboarding', 'forward', 'replace')

      } catch (e:any) {
        if (!e.data) return
        if (e.data.errors) errors.value = e.data.errors
      } finally {
        busy.value = false
      }
    }


    return {canSetPassword, setPassword, busy, errors, form}

  }
});
