
import {
  createAnimation,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter,
  onIonViewWillEnter,
  useIonRouter
} from '@ionic/vue';
import {defineComponent, onMounted, ref} from 'vue';
import LogoMark from "@/components/LogoMark.vue";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import ApiService from "@/services/api/apiService";

export default defineComponent({
  name: 'HomePage',
  components: {
    LogoMark,
    IonContent,
    IonPage,
  },
  setup() {

    const ionRouter = useIonRouter()

    onIonViewDidEnter(async () => {

      const loggedIn = (await Preferences.get({
        key: PreferenceKey.ApiToken
      })).value

      if (!loggedIn) return loading.value = false

      const user = await ApiService.User().get()

      if (user.onboarding.email_verified) {
        if (user.onboarding.password_set) return ionRouter.navigate('/account/onboarding', 'forward', 'pop')
        if (!user.onboarding.password_set) return ionRouter.navigate('/account/password', 'forward', 'pop')
      }

      if (!user.onboarding.email_verified) {
        await ionRouter.navigate('/account/email/not-verified', 'forward', 'pop')
      }

    })


    const loading = ref(true)

    const gotoLogin = async () => {
      await ionRouter.navigate('/login', 'forward', 'push')
    }

    const createAccount = async () => {
      await ionRouter.navigate('/account', 'forward', 'push')
    }

    return {gotoLogin, createAccount, loading}

  }
});
