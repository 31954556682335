
import {
    actionSheetController,
    alertController,
    IonButton,
    IonButtons,
    IonContent, IonHeader,
    IonPage,
    IonTextarea, IonToolbar,
    modalController, toastController,
    useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, onMounted, PropType, Ref, ref} from 'vue';
import {useRoute} from "vue-router";
import InputCheckbox from "@/components/InputCheckbox.vue";
import {PostPrivacy} from "@/definitions/PostPrivacy";
import useCamera from "@/composables/useCamera";
import {CameraResultType, CameraSource} from "@capacitor/camera";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";

export default defineComponent({
    name: 'FeedNewPostPage',
    methods: {useRoute},
    props: {
        comment: {
            type: Object as PropType<FeedPostInterface>,
            required: false
        }
    },
    components: {
        IonToolbar,
        IonHeader,
        IonPage,
        IonContent,
        IonButtons,
        IonButton,
        IonTextarea,
    },
    setup(props) {

        const loading = ref(true)
        const busy = ref(false)
        const posting = ref(false)

        const postContent = ref('')

        onMounted(async () => {
            await reset()

            console.log(props.comment)

            if (props.comment) {
                postContent.value = props.comment.data.original_content
            }

            loading.value = false
        })

        const reset = async () => {
            postContent.value = '',
            posting.value = false
            busy.value = false
        }
        const goBack = async () => {

            if (busy.value || posting.value) return

            const modal = await alertController.create({
                header: 'Discard',
                message: 'Are you sure you discard any changes to this comment?',
                buttons: [
                    {
                        text: 'Yes',
                        role: 'destructive',
                        handler: () => {
                            modalController.dismiss()
                            reset()
                        }
                    },
                    {
                        text: 'No',
                    },

                ]
            })

            await modal.present()

        }

        const canPost = computed(() => {
            return Number(postContent.value.length) > 0
        })

        const updatePost = async () => {
            if (!props.comment) return
            try {
                posting.value = true
                const updatedPost = await ApiService.Feed().updateComment(props.comment.id, postContent.value)
                await reset()
                await modalController.dismiss()
                Observable.emit(ObservableEvent.FeedCommentsChanged, updatedPost)
                posting.value = false
            } catch (e: any) {
                const toast = await toastController.create({
                    message: e.data.message,
                    duration: 3000,
                    position: 'bottom',
                    color: 'danger'
                });
                await toast.present();
                posting.value = false
            }
        }

        return {
            goBack,
            canPost,
            postContent,
            busy,
            loading,
            posting,
            updatePost,
            PostPrivacy
        }

    }
});
