
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter,
  IonButton,
  IonInput,
  IonRouterOutlet, useIonRouter
} from '@ionic/vue';
import {computed, defineComponent, onMounted, onUnmounted, PropType, ref, Ref, watch} from 'vue';
import {StatusBar, Style} from "@capacitor/status-bar";
import {useRouter} from "vue-router";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

export default defineComponent({
  name: 'AccountOnboardingRouterOutlet',
  props: {
    onboardingStep: {
      type: Object as PropType<Ref<number>>,
      required: true
    },
  },
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonRouterOutlet,
    IonContent
  },
  setup(props) {

    const canGoBack = ref(false)
    const ionRouter = useIonRouter()

    const observer = ref()

    onMounted(()=>{
      observer.value = Observable.subscribe((response:any)=>{
        if (response.event === ObservableEvent.IonRouterOutletChanged) {
          canGoBack.value = response.data
        }
      })
    })

    onUnmounted(()=>{
      observer.value?.unsubscribe()
    })

    const goBack = async () => {
      await ionRouter.back()
    }

    const progress = computed(()=>{
      return (props.onboardingStep.value / 9) * 100
    })

    return {goBack, progress, canGoBack}

  }
});
