
import {
    actionSheetController,
    alertController,
    IonButton,
    IonButtons,
    IonContent, IonHeader,
    IonPage,
    IonTextarea, IonToolbar,
    modalController, toastController,
    useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, onMounted, PropType, Ref, ref, watch} from 'vue';
import {useRoute} from "vue-router";
import InputCheckbox from "@/components/InputCheckbox.vue";
import {PostPrivacy} from "@/definitions/PostPrivacy";
import useCamera from "@/composables/useCamera";
import {CameraResultType, CameraSource} from "@capacitor/camera";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import InputField from "@/components/InputField.vue";
import MenuButton from "@/components/MenuButton.vue";
import {SearchUserInterface} from "@/interfaces/SearchUserInterface";
import _ from "lodash";
import UserVerificationCheck from "@/components/UserBadges.vue";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import UserAvatar from "@/components/UserAvatar.vue";

export default defineComponent({
    name: 'FeedNewPostPage',
    methods: {useRoute},
    props: {
        post: {
            type: Object as PropType<FeedPostInterface>,
            required: false
        }
    },
    components: {
      UserAvatar,
        UserVerificationCheck,
        InputField,
        IonPage,
        IonContent,
        IonButtons,
        IonButton,
        IonToolbar,
        IonHeader
    },
    setup() {

        const loading = ref(true)
        const busy = ref(false)
        const creating = ref(false)

        const chatName = ref('')
        const chatPhoto = ref('')
        const chatParticipants: Ref<UserBasicInformationInterface[]> = ref([])

        const participantSearch = ref('')
        const participantSearchResults: Ref<UserBasicInformationInterface[]> = ref([])

        onMounted(async () => {
            loading.value = false
        })

        const goBack = async () => {

            if (busy.value || creating.value) return

            const modal = await alertController.create({
                header: 'Discard Chat',
                message: 'Are you sure you want to discard this group?',
                buttons: [
                    {
                        text: 'Yes',
                        role: 'destructive',
                        handler: () => {
                            modalController.dismiss()
                        }
                    },
                    {
                        text: 'No',
                    },

                ]
            })

            await modal.present()

        }

        const addPhoto = async () => {

            if (busy.value || creating.value) return

            const actionSheet = await actionSheetController.create({
                buttons: [
                    {
                        text: 'Take Photo',
                        handler: async () => {
                            try {
                                const photo = await useCamera(CameraSource.Camera, CameraResultType.Base64)
                                busy.value = true
                                if (Object.prototype.hasOwnProperty.call(photo, 'base64String')) chatPhoto.value = `data:image/${photo.format};base64,${photo.base64String}`
                                busy.value = false
                            } catch (e) {
                                busy.value = false
                            }
                        }
                    },
                    {
                        text: 'Choose Photo',
                        handler: async () => {
                            try {
                                const photo = await useCamera(CameraSource.Photos, CameraResultType.Base64)
                                busy.value = true
                                if (Object.prototype.hasOwnProperty.call(photo, 'base64String')) chatPhoto.value = `data:image/${photo.format};base64,${photo.base64String}`
                                busy.value = false
                            } catch (e) {
                                busy.value = false
                            }
                        }
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel'
                    }
                ]
            })

            await actionSheet.present()

        }

        const canCreate = computed(() => {
            return chatName.value.length && chatParticipants.value.length > 1
        })

        const createChat = async () => {

            if (creating.value) return

            try {
                creating.value = true
                const chat = await ApiService.Chat().create(chatName.value, chatParticipants.value.flatMap(participant => participant.id))
                if (chatPhoto.value) await ApiService.Chat().setImage(chat.id, chatPhoto.value)
                Observable.emit(ObservableEvent.ChatsChanged)
                await modalController.dismiss()
            } catch (e: any) {
                const toast = await toastController.create({
                    message: e.data.message,
                    duration: 3000,
                    position: 'bottom',
                    color: 'danger'
                });
                await toast.present();
                creating.value = false
            }
        }

        const participantSearchResultsCopy: Ref<UserBasicInformationInterface[]> = ref([])

        watch(() => participantSearch.value, async query => {
            participantSearchResults.value = await ApiService.Search().chatUsers(query)
            participantSearchResultsCopy.value = _.cloneDeep(participantSearchResults.value)
        })

        watch(() => chatParticipants.value, async participants => {
            const participantIds = participants.map((p) => p.id)
            participantSearchResults.value = participantSearchResultsCopy.value.filter((participant) => {
                return !participantIds.includes(participant.id)
            })
        }, {deep: true})

        return {
            goBack,
            createChat,
            canCreate,
            addPhoto,
            busy,
            loading,
            creating,
            PostPrivacy,
            chatName,
            chatPhoto,
            participantSearch,
            chatParticipants,
            participantSearchResults
        }

    }
});
