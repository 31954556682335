
import {
  IonContent,
  IonFooter,
  IonPage,
  IonToolbar,
  onIonViewDidEnter,
  onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, onMounted, ref} from 'vue';
import InputCheckbox from "@/components/InputCheckbox.vue";
import ApiService from "@/services/api/apiService";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

export default defineComponent({
  name: 'AccountOnboardingLookingForPage',
  components: {
    InputCheckbox,
    IonContent,
    IonPage,
    IonFooter,
    IonToolbar,
  },
  setup() {

    const loading = ref(false)
    const busy = ref(false)
    const lookingFor = ref([])

    const errors = ref({})
    const ionRouter = useIonRouter()

    onIonViewWillEnter(()=>{
      Observable.emit(ObservableEvent.IonRouterOutletChanged, ionRouter.canGoBack())
    })

    onMounted(async ()=>{
      await getLookingForOptions()
      loading.value = false
    })

    const getLookingForOptions = async () => {
      lookingForOptions.value = (await ApiService.Settings().getDesires()).map((g:any) => {
        return {
          text: g.name,
          value: g.id
        }
      })
    }

    const forward = async () => {
      if (!lookingFor.value.length || busy.value) return
      busy.value = true
      try {
        await ApiService.User().setDesires(lookingFor.value.join(','))
        await ApiService.User().setPreference(PreferenceKey.ProfileShowDesires, showOnProfile.value[0])
        await ionRouter.navigate('/account/onboarding/profile-picture', 'forward', 'push')
      } catch (e: any) {
        if (!e.data) return
        if (e.data.errors) errors.value = e.data.errors
      } finally {
        busy.value = false
      }
    }

    const canProgress = computed(() => {
      return !lookingFor.value.length
    })

    const lookingForOptions:any = ref([])
    const showOnProfile:any = ref([])

    return {busy, forward, lookingFor, lookingForOptions, showOnProfile, errors, canProgress}

  }
});
