
import {IonContent, IonPage, onIonViewWillEnter, useIonRouter,} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import useCamera from "@/composables/useCamera";
import {CameraResultType, CameraSource} from "@capacitor/camera";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

export default defineComponent({
  name: 'AccountOnboardingProfilePicturePage',
  components: {
    IonContent,
    IonPage,
  },
  setup() {

    const busy = ref(false)
    const errors = ref({})
    const ionRouter = useIonRouter()
    const user = ref()

    const avatar = ref()

    const getUser = async () => {
      user.value = await ApiService.User().get()
    }

    onIonViewWillEnter(async () => {
      if (user.value) return
      await getUser()
      Observable.emit(ObservableEvent.IonRouterOutletChanged, ionRouter.canGoBack())
    })

    const choosePhoto = async () => {
      try {
        const image = await useCamera(CameraSource.Photos, CameraResultType.Base64)
        busy.value = true
        avatar.value = undefined
        if (image && image.base64String) await ApiService.User().setProfilePhoto(image.base64String)
        avatar.value = image.base64String
        console.log(avatar.value)
      } catch (e) {
        console.log(e)
        busy.value = false
      } finally {
        busy.value = false
      }
    }

    const forward = async () => {
      await ionRouter.navigate('/account/onboarding/notifications', 'forward', 'push')
    }

    return {busy, errors, choosePhoto, avatar, user, forward}

  }
});
