import {modalController} from "@ionic/vue";
import FeedNewPostPage from "@/views/feed/FeedNewPostPage.vue";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import {UserInterface} from "@/interfaces/UserInterface";

export function usePost() {

    const create = async (authUser: UserInterface) => {
        const modal = await modalController.create({
            component: FeedNewPostPage,
            mode: 'ios',
            componentProps: {
                authUser
            },
            presentingElement: document.getElementById('tabs') as HTMLElement,
        })
        await modal.present()
    }

    return {
        create
    }

}