
import {
  actionSheetController,
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonPage,
  IonTextarea,
  modalController, toastController,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, onMounted, PropType, Ref, ref} from 'vue';
import {useRoute} from "vue-router";
import InputCheckbox from "@/components/InputCheckbox.vue";
import {PostPrivacy} from "@/definitions/PostPrivacy";
import useCamera from "@/composables/useCamera";
import {CameraResultType, CameraSource} from "@capacitor/camera";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";
import {UserGalleryInterface} from "@/interfaces/UserGalleryInterface";

export default defineComponent({
  name: 'GalleryManagement',
  methods: {useRoute},
  components: {
    IonPage,
    IonContent,
    IonButtons,
    IonButton,
  },
  setup(props) {

    const loading = ref(true)
    const busy = ref(false)
    const saving = ref(false)

    const galleryPhotos: Ref<string[]> = ref([])

    onMounted(async () => {
      const userGalleryPhotos = await ApiService.User().gallery(-1)
      galleryPhotos.value = userGalleryPhotos.map((m:UserGalleryInterface)=>{
        return m.url
      })
      loading.value = false
    })

    const goBack = async () => {

      if (busy.value || saving.value) return

      const modal = await alertController.create({
        header: 'Discard Changes',
        message: 'Are you sure you want to discard your changes?',
        buttons: [
          {
            text: 'Yes',
            role: 'destructive',
            handler: () => {
              modalController.dismiss()
            }
          },
          {
            text: 'No',
          },

        ]
      })

      await modal.present()
    }

    const addPhoto = async () => {

      if (busy.value || saving.value) return

      try {
        const photo = await useCamera(CameraSource.Photos, CameraResultType.Base64)
        if (photo.format) {
          busy.value = true
          setTimeout(() => {
            galleryPhotos.value = [...[`data:image/${photo.format};base64,${photo.base64String}`], ...galleryPhotos.value]
            busy.value = false
          }, 2000)
        }
      } catch (e) {
        busy.value = false
      }

    }

    const removePhoto = async (index: number) => {

      if (busy.value || saving.value) return

      const modal = await alertController.create({
        header: 'Remove Photo',
        message: 'Are you sure you want to remove this photo from your gallery?',
        buttons: [
          {
            text: 'Yes',
            role: 'destructive',
            handler: async () => {
              galleryPhotos.value.splice(index, 1)
            }
          },
          {
            text: 'No',
          },

        ]
      })

      await modal.present()

    }

    const saveChanges = async () => {
      try {
        saving.value = true
        await ApiService.User().updateGalleryMedia(galleryPhotos.value)
        await Observable.emit(ObservableEvent.UserGalleryChanged)
        await modalController.dismiss()
      } catch (e:any) {
        const toast = await toastController.create({
          message: e.data.message,
          duration: 3000,
          position: 'bottom',
          color: 'danger'
        });
        await toast.present();
        saving.value = false
      }
    }

    return {
      goBack,
      galleryPhotos,
      addPhoto,
      removePhoto,
      saveChanges,
      busy,
      loading,
      saving,
    }

  }
});
