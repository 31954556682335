
import {
  IonButton,
  IonButtons,
  IonContent,
  IonPage, modalController, onIonViewDidEnter, toastController,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, inject, onMounted, onUnmounted, Ref, ref} from 'vue';
import {useRoute} from "vue-router";
import {FriendUserInterface} from "@/interfaces/FriendUserInterface";
import ApiService from "@/services/api/apiService";
import MenuButton from "@/components/MenuButton.vue";
import {DateTime} from "luxon";
import {useUser} from "@/composables/useUser";
import InputTextarea from "@/components/InputTextarea.vue";
import {Preferences} from "@capacitor/preferences";

export default defineComponent({
  name: 'ReportUserPage',
  methods: {useRoute},
  components: {
    IonPage,
    IonContent,
    IonButtons,
    IonButton,
    InputTextarea
  },
  setup() {

    const loading = ref(true)
    const busy = ref(false)
    const ionRouter = useIonRouter()
    const route = useRoute()

    const problem = ref('')
    const errors = ref({})


    const goBack = async () => {
      await ionRouter.back()
    }

    onIonViewDidEnter(()=>{
      loading.value = false
    })

    const send = async () => {

      if (busy.value) return

      try {
        busy.value = true
        await ApiService.User().reportProblem(problem.value)
        await goBack()
        const toast = await toastController.create({
          message: `Thank you for reporting your problem. The Pineapple team aim to respond within 48 hours.`,
          duration: 3000,
          position: 'bottom',
          color: 'primary'
        });
        await toast.present();
      } catch (e) {
        // busy.value = false
      } finally {
        busy.value = false
      }


    }

    return {
      goBack,
      busy,
      loading,
      problem,
      errors,
      send
    }

  }
});
