
import {
  IonButton,
  IonButtons,
  IonContent, IonFooter,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, inject, onMounted, reactive, Ref, ref, watch} from 'vue';
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";
import InputField from "@/components/InputField.vue";
import InputCheckbox from "@/components/InputCheckbox.vue";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {useGoogleMaps} from "@/composables/useGoogleMaps";

export default defineComponent({
  name: 'ProfileSettingsLocation',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonButtons,
    IonButton,
    IonTitle,
    InputCheckbox,
    IonFooter,
    InputField
  },
  setup() {

    const user: Ref<UserInterface | undefined> = inject('user') as Ref<UserInterface | undefined>
    const loading = ref(true)
    const ionRouter = useIonRouter()

    const busy = ref(false)
    const errors = ref({})
    const showOnProfile: any = ref([true])

    const location = ref()
    const locationName = ref(user.value?.location?.name ?? '')
    const locationSearch = ref('')
    const locations = ref([])

    onIonViewWillEnter(async () => {
      const pref = await ApiService.User().getPreference(PreferenceKey.ProfileShowLocation)
      showOnProfile.value.push(pref ? !!Number(pref) : true)
      loading.value = false
    })

    const goBack = async () => {
      if (busy.value) return
      await ionRouter.back()
    }

    const save = async () => {
      if (busy.value || !location.value) return
      errors.value = {}
      busy.value = true
      try {
        await ApiService.User().setLocation({
          name: locationName.value,
          lat: location.value.geometry.location.lat(),
          lng: location.value.geometry.location.lng(),
        })
        await ApiService.User().setPreference(PreferenceKey.ProfileShowLocation, showOnProfile.value[0])
        await Observable.emit(ObservableEvent.UserChanged)
        setTimeout(() => {
          ionRouter.back()
          busy.value = false
        }, 1000)
      } catch (e: any) {
        if (!e.data) return
        if (e.data.errors) errors.value = e.data.errors
        busy.value = false
      }
    }

    const places = ref()

    onMounted(async () => {
      await useGoogleMaps().loadSDK()
      const attributionEl: any = document.querySelector('#attribution')
      // eslint-disable-next-line no-undef
      places.value = new google.maps.places.PlacesService(attributionEl)
      loading.value = false
    })

    const updateLocationSearch = async (value:any) => {
      locationSearch.value = value
    }

    const chooseLocation = async (value:any) => {
      location.value = value
      locationName.value = value.formatted_address
      locations.value = []
    }

    watch(() => locationSearch.value, searchValue => {

      if (!searchValue) {
        locations.value = []
        location.value = undefined
        return
      }

      if (searchValue === locationName.value) return

      // eslint-disable-next-line no-undef
      places.value.textSearch({
        query: searchValue,
        types: ['(cities)']
      }, (response: any) => {
        locations.value = response
      })

    })

    return {loading, goBack, user, busy, errors, save, showOnProfile, locationSearch, locations, location, updateLocationSearch, chooseLocation, locationName}

  }
});
