
import {
  IonContent,
  IonPage,
  IonInput,
  toastController,
  IonSkeletonText, useIonRouter
} from '@ionic/vue';
import {defineComponent, onMounted, onUnmounted, Ref, ref} from 'vue';
import {useRouter} from "vue-router";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {UserService} from "@/services/api/user/userService";
import {App, AppState} from "@capacitor/app";
import {UserInterface} from "@/interfaces/UserInterface";

export default defineComponent({
  name: 'AccountNotVerifiedPage',
  components: {
    IonContent,
    IonPage,
    IonSkeletonText
  },
  setup() {

    const ionRouter = useIonRouter()
    const busy = ref(false)
    const loading = ref(true)
    const checkingStatus = ref(false)

    const userService = new UserService()
    const user: Ref<UserInterface | undefined> = ref()

    onMounted(async () => {
      await getUser()
      loading.value = false

      document.addEventListener('visibilitychange', async () => {
        await checkStatus()
      });

    })

    const checkStatus = async () => {
      checkingStatus.value = true
      await getUser()

      if (user.value?.onboarding.email_verified && user.value.onboarding.password_set) {
        await App.removeAllListeners()
        return ionRouter.navigate('/account/onboarding', 'none', 'replace')
      }
      if (user.value?.onboarding.email_verified && !user.value.onboarding.password_set) {
        await App.removeAllListeners()
        return ionRouter.navigate('/account/password', 'none', 'replace')
      }
      checkingStatus.value = false
    }

    const getUser = async () => {
      user.value = await userService.get()
    }

    const logout = async () => {
      if (loading.value || busy.value) return
      await Preferences.clear()
      await ionRouter.navigate('/', 'back', 'pop')
    }

    const resend = async () => {
      if (busy.value || loading.value) return
      try {
        busy.value = true
        await userService.sendEmailVerification()

        const toast = await toastController.create({
          message: `A new verification link has been sent to ${user.value?.email}`,
          duration: 3000,
          position: 'top',
          color: 'primary'
        });

        await toast.present();

      } catch (e) {
        //
      } finally {
        busy.value = false
      }
    }

    return {logout, busy, resend, loading, user, checkingStatus}

  }
});
