
import {
  IonContent,
  IonPage,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import {useRouter} from "vue-router";
import InputField from "@/components/InputField.vue";
import AuthService from "@/services/api/auth/authService";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {UserService} from "@/services/api/user/userService";

export default defineComponent({
  name: 'LoginPage',
  components: {
    InputField,
    IonContent,
    IonPage,
  },
  setup() {

    const router = useRouter()
    const ionRouter = useIonRouter()

    const form = reactive({
      email: '',
      password: ''
    })

    const errors = ref({})

    const busy = ref(false)

    const goBack = async () => {
      await router.back()
    }

    const forgotPassword =  async () => {
      window.open(`${process.env.VUE_APP_WEB_URL}/forgot-password`, '_system')
    }

    const signIn = async () => {
      if (!canSignIn.value || busy.value) return

      try {
        busy.value = true
        errors.value = {}
        const [authService, userService] = [new AuthService(), new UserService()]

        await authService.login(form.email, form.password)
        await ionRouter.navigate('/', 'forward', 'replace')

      } catch (e:any) {
          if (!e.data) return
        if (e.data.errors) errors.value = e.data.errors
      } finally {
        busy.value = false
      }

    }

    const canSignIn = computed(()=>{
      if (form.email.length && form.password.length) return true
      return false
    })

    return {goBack,forgotPassword, signIn, canSignIn, form, busy, errors}

  }
});
