
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter,
  IonButton,
  IonInput,
  IonRouterOutlet, useIonRouter
} from '@ionic/vue';
import {computed, defineComponent, onMounted, onUnmounted, PropType, ref, Ref, watch} from 'vue';
import {StatusBar, Style} from "@capacitor/status-bar";
import {useRouter} from "vue-router";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

export default defineComponent({
  name: 'AccountVerificationRouterOutlet',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonRouterOutlet,
    IonContent
  },
  setup(props) {

    const canGoBack = ref(false)
    const ionRouter = useIonRouter()

    const goBack = async () => {
      await ionRouter.back()
    }

    return {goBack, canGoBack}

  }
});
