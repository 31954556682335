
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter,
  IonButton,
  IonInput,
  IonRouterOutlet
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {StatusBar, Style} from "@capacitor/status-bar";
import {useRouter} from "vue-router";

export default defineComponent({
  name: 'LoginRouterOutlet',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonRouterOutlet,
    IonContent
  },
  setup() {

    const router = useRouter()

    const goBack = async () => {
      await router.back()
    }

    const forgotPassword = async () => {
      await router.push({name: 'ForgotPassword'})
    }

    return {goBack,forgotPassword}

  }
});
