
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonList,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonToolbar,
    useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, inject, onMounted, onUnmounted, Ref, ref} from 'vue';
import InAppSubscription from "@/components/InAppSubscription.vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import ApiService from "@/services/api/apiService";
import {UserInterface} from "@/interfaces/UserInterface";
import {ChatInterface} from "@/interfaces/ChatInterface";
import {DateTime} from "luxon";
import ChatListItem from "@/components/ChatListItem.vue";

export default defineComponent({
    name: 'FlirtPage',
    components: {
        ChatListItem,
        IonButtons, IonButton,
        InAppSubscription,
        IonPage,
        IonToolbar,
        IonContent,
        IonHeader,
        IonRefresher,
        IonRefresherContent,
        IonItem,
        IonList,
        IonItemSliding,
        IonItemOption,
        IonItemOptions
    },
    setup() {

        const loading = ref(true)
        const user: Ref<UserInterface | undefined> = inject('user') as Ref<UserInterface | undefined>
        const chats: Ref<ChatInterface[]> = ref([])
        const ionRouter = useIonRouter()

        onMounted(async () => {
            await getChats()
            loading.value = false
        })

        const getChats = async () => {
            chats.value = await ApiService.Chat().getArchived()
        }

        const pullToRefresh = async (el: any) => {
            await getChats()
            setTimeout(() => {
                el.target.complete()
            }, 500)
        }

        const isSubscribed = computed(() => {
            return user.value?.subscribed
        })

        const messageTime = (time: string) => {
            const dateTime = DateTime.fromISO(time)

            // is today
            if (DateTime.now().toISODate() === dateTime.toISODate()) return dateTime.toFormat('HH:mm')

            // is yesterday
            if (DateTime.now().minus({day: 1}).toISODate() === dateTime.toISODate()) return 'Yesterday'
            return dateTime.toFormat('EEE')

        }


        const deleteChat = async (chatId: number, chatIndex: number) => {
            chats.value.splice(chatIndex, 1)
            await ApiService.Chat().delete(chatId)
        }
        const unarchiveChat = async (chatId: number, chatIndex: number) => {
            chats.value.splice(chatIndex, 1)
            await ApiService.Chat().unarchive(chatId)
            await Observable.emit(ObservableEvent.ChatsChanged)
        }

        const goBack = () => {
            ionRouter.back()
        }

        return {isSubscribed, goBack, deleteChat, unarchiveChat, pullToRefresh, loading, chats, messageTime}

    }
});
