
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, onIonViewDidEnter} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import {StatusBar, Style} from "@capacitor/status-bar";
import LogoMark from "@/components/LogoMark.vue";

import {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import {useRouter} from "vue-router";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {DateTime} from "luxon";

export default defineComponent({
  name: 'OnboardingPage',
  components: {
    Swiper,
    SwiperSlide,
    LogoMark,
    IonContent,
    IonPage,
  },
  setup() {

    const over18 = ref(false)

    const slides = ref([
      {
        image: '/assets/img/onboarding1.png',
        title: 'Meet couples & singles',
        text: 'Pineapple is the app where it\'s at, a place to meet open-minded people.'
      },
      {
        image: '/assets/img/onboarding2.png',
        title: 'Explore the feed',
        text: 'Find out what people in your area are up to and share your experiences.'
      },
      {
        image: '/assets/img/onboarding3.png',
        title: 'Verified users',
        text: 'We pride ourselves on real, verified users. A community made by swingers for swingers.'
      },
      {
        image: '/assets/img/onboarding4.png',
        title: 'Flirt',
        text: 'Chat with people who take your fancy, one on one or as a group. '
      }
    ])

    const swiperRef:any = ref(null)

    const onSwiper = async (swiper:any) => {
      swiperRef.value = swiper
    }

    const router = useRouter()

    const next = async () => {
      if (swiperRef.value.isEnd) {
        await router.push({name: 'Welcome'})
        await Preferences.set({
          key: PreferenceKey.CompletedOnboarding,
          value: DateTime.now().toISO()
        })
        return
      }
      swiperRef.value.slideNext()
    }

    return {
      over18,
      slides,
      swiperModules: [Pagination],
      onSwiper,
      next
    }

  }
});
