import {Subject} from "rxjs";
import AppResumed from "@/views/common/AppResumed.vue";

export enum ObservableEvent {
    UserChanged = "UserChanged",
    UserUpdateComplete = "UserUpdateComplete",
    IonRouterOutletChanged = "IonRouterOutletChanged",
    SubscriptionChanged = "SubscriptionChanged",
    NotificationsChanged = "NotificationsChanged",
    PushNotificationTapped = "PushNotificationTapped",
    FeedChanged = "FeedChanged",
    FeedPostChanged = "FeedPostChanged",
    FeedPostDeleted = "FeedPostDeleted",
    FeedCommentsChanged = "FeedCommentsChanged",
    FeedScrollTop = "FeedScrollTop",
    SearchScrollTop = "SearchScrollTop",
    EventsScrollTop = "EventsScrollTop",
    UserGalleryChanged = "UserGalleryChanged",
    FriendsChanged = "FriendsChanged",
    EventsChanged = "EventsChanged",
    ChatsChanged = "ChatsChanged",
    BlockedUsersChanged = "BlockedUsersChanged",
    PartnersChanged = "BlockedUsersChanged",
    FeedPreferencesChanged = "FeedPreferencesChanged",
    VerificationStatusChanged = "VerificationStatusChanged",
    UnseenMessagesChanged = "UnseenMessagesChanged",
    ShowTabBar = "ShowTabBar",
    HideTabBar = "HideTabBar",
    ShowPrivacyScreen = "ShowPrivacyScreen",
    UpdateCurrentPosition = "UpdateCurrentPosition",
    AppResumed = "AppResumed",
    AppPaused = "AppPaused",
    PWAUpdateAvailable = 'PWAUpdateAvailable',
    RefreshFeed = 'RefreshFeed',
    RefreshEventsFeed = 'RefreshEventsFeed',
    FeedShowXRated = 'FeedShowXRated',
    FeedHideXRated = 'FeedHideXRated',
    PullToRefreshExplore = 'PTRE',
    PullToRefreshAttending = 'PTRA',
    PullToRefreshInterested = 'PTRI',
    PullToRefreshMyEvents = 'PTRME',
    PullToRefreshMyTickets = 'PTRMT',
    PullToRefreshForYou = 'PTRFY',
    PullToRefreshFollowing = 'PTRF',
    PullToRefreshFriends = 'PTRFF',
    PullToRefreshMeet = 'PTRM',
    PendingEventResponsesChanged = 'PendingEventResponsesChanged'
}


export interface ObservableSubscription {
    event: ObservableEvent,
    data: any
}

export class Observable {

    private static subject = new Subject()

    public static subscribe(callback: any) {
        return this.subject.asObservable().subscribe(data => {
            callback(data)
        })
    }

    public static emit(event: ObservableEvent, value?: any): void {
        return this.subject.next({
            event: event,
            data: value || null
        })
    }

}
