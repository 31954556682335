
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  isPlatform,
  modalController,
  toastController,
} from '@ionic/vue';
import {computed, defineComponent, onMounted, ref} from 'vue';
import {Glassfy} from "capacitor-plugin-glassfy";
import getSymbolFromCurrency from "currency-symbol-map";
import _ from 'lodash';
import MenuButton from "@/components/MenuButton.vue";

export default defineComponent({
  name: 'SubscriptionPage',
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons
  },
  setup() {

    const busy = ref(false)
    const restoreBusy = ref(false)
    const loading = ref(true)
    const errors = ref({})
    const subscription = ref()


    const isIOS = computed(() => {
      return isPlatform('ios')
    })

    onMounted(async () => {
      const offering = (await Glassfy.offerings()).all.find(o => o.offeringId === 'subscriptions')
      subscription.value = offering?.skus.find((sku: any) => sku.skuId === 'monthly_premium_membership')
      loading.value = false
    })

    const subscribe = async () => {
      if (busy.value || restoreBusy.value) return
      try {
        busy.value = true
        const sku = _.cloneDeep(subscription.value)
        await Glassfy.purchaseSku({sku})
        await modalController.dismiss({
          subscribed: true
        })
      } catch (e: any) {
        const toast = await toastController.create({
          message: e.message,
          duration: 3000,
          position: 'bottom',
          color: 'danger'
        });
        await toast.present();
      } finally {
        busy.value = false
      }
    }

    const price = computed(() => {
      return `${getSymbolFromCurrency(subscription.value.product.currencyCode)}${subscription.value.product.price.toFixed(2)}/month`
    })

    const noThankYou = async () => {
      if (busy.value || restoreBusy.value) return
      await modalController.dismiss()
    }

    const restorePurchase = async () => {
      if (busy.value || restoreBusy.value) return
      restoreBusy.value = true

      try {
        await Glassfy.restorePurchases()
        await modalController.dismiss({
          subscribed: true
        })
      } catch (e: any) {

        const toast = await toastController.create({
          message: e.message,
          duration: 3000,
          position: 'bottom',
          color: 'danger'
        });

        await toast.present();
      } finally {
        restoreBusy.value = false
      }
    }


    return {busy, errors, noThankYou, restorePurchase, subscribe, price, loading, restoreBusy, isIOS}

  }
});
