import ApiServiceBase from "@/services/api/apiServiceBase";
import {Device} from "@capacitor/device";
import {alertController, isPlatform, useIonRouter} from "@ionic/vue";
import router from "@/router";
import {Preferences} from "@capacitor/preferences";
import {UserService} from "@/services/api/user/userService";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import OneSignal from "onesignal-cordova-plugin";
import {useOneSignal} from "@onesignal/onesignal-vue3";

export default class AuthService extends ApiServiceBase {

    public async login(email: string, password: string) {
      let device: any = {name: 'Browser'}
      if (isPlatform('capacitor')) device = await Device.getInfo()
        const response = await this.http?.post('/login', {email, password, device_name: device.name}, false);
        if (response.token) await Preferences.set({
            key: PreferenceKey.ApiToken,
            value: response.token
        })
    }

    public async register(email: string, password: string) {
      return this.http?.post('/register', {
          email,
          password,
          password_confirmation: password,
        }, false);
    }

    public async logout(callback?: any) {

        const alert = await alertController.create({
          header: 'Logout',
          message: 'Are you sure you want to logout?',
          buttons: [
            {text: 'Cancel', role: "cancel"},
            {
              text: 'Logout', role: "destructive", handler: async () => {

                const userService = new UserService()
                // await userService.revokeDeviceToken()


                await this.http?.post('/logout', {
                    token: (await Preferences.get({key: PreferenceKey.ApiToken})).value
                });
                await alertController.dismiss(true)
                await Preferences.clear()

                await useOneSignal().logout()

                if (callback) callback()

              }
            }
          ]
        })

        await alert.present()
        await alert.onWillDismiss()

    }

  public async deleteAccount(callback?: any) {

    const alert = await alertController.create({
      header: 'Delete Account',
      message: 'Are you sure you want to delete your account?',
      buttons: [
        {text: 'Cancel', role: "cancel"},
        {
          text: 'Yes', role: "destructive", handler: async () => {

            await this.http?.delete('/user', {});
            await Preferences.clear()
            await router.replace('/')

            if (callback) callback()

          }
        }
      ]
    })

    await alert.present()

  }


}
