
import {
  IonContent, IonHeader, IonLabel,
  IonPage, IonRange, IonRefresher, IonRefresherContent, IonSegment, IonSegmentButton, IonSkeletonText, IonToolbar,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, inject, onMounted, reactive, Ref, ref, watch} from 'vue';
import {UserInterface} from "@/interfaces/UserInterface";
import ApiService from "@/services/api/apiService";
import InputField from "@/components/InputField.vue";
import _ from "lodash";
import {Geolocation, Position} from "@capacitor/geolocation";
import pluralize from "pluralize";
import UserListItem from "@/components/UserListItem.vue";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import SearchTags from "@/components/SearchTags.vue";
import NearbyList from "@/components/NearbyList.vue";

export default defineComponent({
  name: 'ProfilePage',
  components: {
    NearbyList,
    SearchTags,
    UserListItem,
    IonSegment, IonSegmentButton, IonLabel,
    InputField,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
  },
  setup() {

    const nearbyLoading = ref(true)
    const peopleLoading = ref(false)

    const user: Ref<UserInterface|any> = inject('user') as Ref<UserInterface | undefined>
    const ionRouter = useIonRouter()

    const search = ref('')
    const searchResults: Ref<UserBasicInformationInterface[]> = ref([])
    const searchResultsCopy: Ref<UserBasicInformationInterface[]> = ref([])

    watch(() => search.value, async query => {
      peopleLoading.value = true
      searchResults.value = await ApiService.Search().users(query)
      searchResultsCopy.value = _.cloneDeep(searchResults.value)
      peopleLoading.value = false
    })

    const viewUserProfile = async (userId: number) => {
      ionRouter.navigate({
        name: 'PineappleSearchUserProfile',
        params: {
          userId
        }
      }, 'forward', 'push')
    }


    const tab = ref('nearby')
    const tabChanged = (event: any) => {
      tab.value = event.detail.value
    }

    const currentLocation = reactive({
      lat: 0,
      lng: 0
    })

    onMounted(async () => {
      await getCurrentLocation()
      await getNearbyResults()
    })

    const nearbyRadius = ref(40)
    const nearbyResults: Ref<UserBasicInformationInterface[]> = ref([])
    const currentPosition: Ref<Position | undefined> = ref()
    const getCurrentLocation = async () => {
      currentPosition.value = await Geolocation.getCurrentPosition();
    }

    const getNearbyResults = async () => {
      if (!currentPosition.value) return
      nearbyLoading.value = true
      await getCurrentLocation()
      nearbyResults.value = await ApiService.Search().nearby(currentPosition.value.coords.latitude, currentPosition.value.coords.longitude, nearbyRadius.value)
      nearbyLoading.value = false
    }

    const refreshNearby = async (ev: any) => {
      await getCurrentLocation()
      await getNearbyResults()
      ev.target.complete()
    }


    const radiusLabel = computed(() => {
      return `${nearbyRadius.value} ${pluralize('mile', nearbyRadius.value)} away`
    })

    return {
      user,
      nearbyLoading,
      peopleLoading,
      search,
      searchResults,
      nearbyResults,
      refreshNearby,
      viewUserProfile,
      tabChanged,
      tab,
      nearbyRadius,
      radiusLabel
    }

  }
});
