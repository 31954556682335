

import {computed, defineComponent, inject, onDeactivated, onMounted, onUnmounted, PropType, Ref, ref, watch} from 'vue';
import {useRoute} from "vue-router";
import {alertController, IonContent, IonToggle, modalController, toastController} from "@ionic/vue";
import ApiService from "@/services/api/apiService";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {useUser} from "@/composables/useUser";
import FeedNewPostPage from "@/views/feed/FeedNewPostPage.vue";
import FeedEditComment from "@/views/feed/FeedEditComment.vue";

export default defineComponent({
    name: 'FeedCommentContextMenu',
    methods: {useRoute},
    props: {
        comment: {
            type: Object as PropType<FeedPostInterface>,
            required: true
        },
    },
    components: {
        // IonContent,
    },
    setup(props) {

        const loading = ref(true)
        const reportBusy = ref(false)
        const deleteBusy = ref(false)
        const winkBusy = ref(false)
        const authUserId = ref()

        onMounted(async () => {
            deleteBusy.value = false
            authUserId.value = parseInt((await Preferences.get({key: PreferenceKey.UserId})).value as string)
            loading.value = false
        })

        const editPost = async () => {
            await modalController.dismiss()
            const modal = await modalController.create({
                component: FeedEditComment,
                componentProps: {
                    comment: props.comment
                },
                mode: 'ios',
                presentingElement: document.getElementById('tabs') as HTMLElement,
            })
            await modal.present()
        }

        const deletePost = async () => {

            if (isBusy.value) return

            const alertCtrl = await alertController.create({
                header: 'Delete Comment',
                message: 'Are you sure you want to delete this comment?',
                buttons: [
                    {
                        text: 'Yes',
                        role: 'destructive',
                        handler: async () => {
                            try {
                                deleteBusy.value = true
                                await alertCtrl.dismiss()
                                await ApiService.Feed().deletePostComment(props.comment.id)
                                Observable.emit(ObservableEvent.FeedCommentsChanged)
                                await modalController.dismiss()
                                deleteBusy.value = false
                            } catch (e: any) {
                                const toast = await toastController.create({
                                    message: e.data.message,
                                    duration: 3000,
                                    position: 'bottom',
                                    color: 'danger'
                                });
                                await toast.present();
                                deleteBusy.value = false
                            }
                        }
                    },
                    {
                        text: 'No',
                    }
                ]
            })

            await alertCtrl.present()

        }

        const commentRef: Ref<FeedPostInterface> = ref(props.comment)

        const reportComment = async () => {
            if (isBusy.value) return
            try {


              const alert = await alertController.create({
                header: 'Report comment?',
                message: 'Are you sure you want to report this comment?',
                buttons: [
                  {
                    text: 'Cancel',
                  },
                  {
                    text: 'Yes',
                    role: 'destructive',
                    handler: async () => {

                      alertController.dismiss()
                      reportBusy.value = true
                      await ApiService.Feed().reportPostComment(props.comment.id)
                      reportBusy.value = false

                      await modalController.dismiss()
                      const toast = await toastController.create({
                        header: 'Comment Reported',
                        message: 'Thank you for reporting this comment, is has been flagged for review by our team.',
                        duration: 3000,
                        position: 'bottom',
                        color: 'primary'
                      });
                      await toast.present();

                    }
                  },

                ]
              })
              await alert.present()

            } catch (e: any) {
                const toast = await toastController.create({
                    message: e.data.message,
                    duration: 3000,
                    position: 'bottom',
                    color: 'danger'
                });
                await toast.present();
                deleteBusy.value = false
            }
        }

        const postOwner = computed(() => {
            return props.comment.posted_by.id === authUserId.value
        })

        const isBusy = computed(() => {
            return (reportBusy.value || deleteBusy.value || winkBusy.value)
        })


        const wink = async () => {
            winkBusy.value = true
            await useUser().winkAtUser(props.comment.posted_by.id, props.comment.posted_by.name)
            winkBusy.value = false
        }


        return {loading, deletePost, deleteBusy, wink, winkBusy, reportBusy, reportComment, commentRef, postOwner, editPost}

    }
});
