
import {
  IonButton,
  IonButtons,
  IonContent, IonFooter,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, inject, Ref, ref} from 'vue';
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";
import InputField from "@/components/InputField.vue";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import InputCheckbox from "@/components/InputCheckbox.vue";

export default defineComponent({
  name: 'ProfileSettingsHeight',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonButtons,
    IonButton,
    IonTitle,
    InputField,
    IonFooter,
    InputCheckbox
  },
  setup() {

    const user: Ref<UserInterface | undefined> = inject('user') as Ref<UserInterface | undefined>
    const loading = ref(true)
    const ionRouter = useIonRouter()

    const busy = ref(false)
    const height = ref(user.value?.height ? `${user.value?.height?.cm}` : '172')
    const errors = ref({})

    const showOnProfile: any = ref([true])

    onIonViewWillEnter(async () => {
      const pref = Boolean(Number((await ApiService.User().authUser()).preferences[PreferenceKey.ProfileShowHeight]))
      showOnProfile.value = [pref]
      loading.value = false
    })

    const goBack = async () => {
      await ionRouter.back()
    }

    const save = async () => {
      if (busy.value) return
      errors.value = {}
      busy.value = true
      try {
        await ApiService.User().setHeight(Number(height.value))
        await ApiService.User().setPreference(PreferenceKey.ProfileShowHeight, showOnProfile.value[0])
        await Observable.emit(ObservableEvent.UserChanged)
        setTimeout(() => {
          ionRouter.back()
          busy.value = false
        }, 1000)
      } catch (e: any) {
        if (!e.data) return
        if (e.data.errors) errors.value = e.data.errors
        busy.value = false
      }
    }

    const heightInFtIn = computed(() => {
      if (!height.value) return ''

      const convertedTotalInches = Number(height.value) / 2.54
      const convertedFeet = Math.floor(convertedTotalInches / 12)
      const convertedInches = convertedTotalInches - 12 * convertedFeet
      const feet = Math.round(convertedFeet)
      const inches = Math.round(convertedInches)
      return `${feet} ft ${inches} inches`
    })

    return {loading, goBack, user, busy, height, errors, save, showOnProfile, heightInFtIn}

  }
});
