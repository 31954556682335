
import {
  IonButton,
  IonButtons,
  IonContent,
  IonPage, onIonViewDidEnter,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, inject, onMounted, onUnmounted, Ref, ref} from 'vue';
import {useRoute} from "vue-router";
import {FriendUserInterface} from "@/interfaces/FriendUserInterface";
import ApiService from "@/services/api/apiService";
import MenuButton from "@/components/MenuButton.vue";
import {DateTime} from "luxon";
import {useUser} from "@/composables/useUser";
import InputTextarea from "@/components/InputTextarea.vue";
import {Preferences} from "@capacitor/preferences";

export default defineComponent({
  name: 'ReportUserPage',
  methods: {useRoute},
  components: {
    IonPage,
    IonContent,
    IonButtons,
    IonButton,
    InputTextarea
  },
  setup() {

    const loading = ref(true)
    const busy = ref(false)
    const ionRouter = useIonRouter()
    const route = useRoute()

    const reason = ref('')
    const errors = ref({})


    const goBack = async () => {
      await ionRouter.back()
    }

    onIonViewDidEnter(()=>{
      loading.value = false
    })

    const forward = async () => {
      await Preferences.set({key: 'REPORT_USER_REASON', value: reason.value})
      ionRouter.navigate({
        name: 'ReportUserConfirmationPage',
        params: {
          userId: route.params.userId
        }
      }, 'forward', 'push')
    }

    return {
      goBack,
      busy,
      loading,
      reason,
      errors,
      forward
    }

  }
});
