import ApiServiceBase from "@/services/api/apiServiceBase";
import {ChatInterface} from "@/interfaces/ChatInterface";
import {ChatMessageInterface} from "@/interfaces/ChatMessageInterface";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {UnseenMessagesInterface} from "@/interfaces/UnseenMessagesInterface";

export class ChatService extends ApiServiceBase {

  public async get(): Promise<ChatInterface[]> {
    return this.http?.get('/chat');
  }

  public async getById(chatId: number): Promise<ChatInterface[]> {
    return this.http?.get(`/chat/${chatId}`);
  }

  public async with(userId:number): Promise<ChatInterface> {
    return this.http?.get(`/chat/user/${userId}`);
  }

  public async getArchived(): Promise<ChatInterface[]> {
    return this.http?.get('/chat/archive');
  }

  public async postMessage(chatId:number, message: string, media?: string|null): Promise<ChatMessageInterface> {
    const payload:any = {message}
    if (media) payload['media'] = media
    return this.http?.put(`/chat/${chatId}/message`, payload);
  }

  public async deleteMessage(commentId: number): Promise<ChatInterface> {
    return this.http?.delete(`/chat/message/${commentId}`, {});
  }

  public async conversation(chatId:number, page = 1): Promise<any> {
    return this.http?.get(`/chat/${chatId}/conversation?page=${page}`);
  }

  public async create(name: string, participants: number[]): Promise<ChatInterface> {
    return this.http?.put('/chat', {
      name, participants
    });
  }

  public async isActive(chatId:number, value: boolean): Promise<any> {

    if (value) await Preferences.set({ key: PreferenceKey.ActiveChatId, value: `${chatId}` })
    if (!value) await Preferences.remove({key: PreferenceKey.ActiveChatId})
    return this.http?.put(`/chat/${chatId}/active`, {active: value});
  }

  public async delete(chatId: number): Promise<ChatInterface> {
    return this.http?.delete(`/chat/${chatId}`, {});
  }

  public async archive(chatId: number): Promise<any> {
    return this.http?.put(`/chat/${chatId}/archive`, {});
  }

  public async unarchive(chatId: number): Promise<any> {
    return this.http?.put(`/chat/${chatId}/unarchive`, {});
  }

  public async unseenMessages(): Promise<UnseenMessagesInterface> {
    return this.http?.get(`/chat/unseen`, {});
  }

  public async setImage(chatId: number, base64Image: string): Promise<ChatInterface> {
    return this.http?.post(`/chat/${chatId}/image` , {
      image: base64Image
    });
  }

}
