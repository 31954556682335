
import {
  IonContent,
  IonPage,
  IonInput
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {useRouter} from "vue-router";

export default defineComponent({
  name: 'AccountBiometricsPage',
  components: {
    IonContent,
    IonPage,
  },
  setup() {

    const router = useRouter()

    const forward = async () => {
      await router.push({name:'AccountOnboarding'})
    }

    return {forward}

  }
});
